import React from 'react'
import Footer from '../components/Footer'
import Navbar from '../components/Navbar'

export default function Home() {
  return (
    <>
  {/*[if lte IE 9]> <p class="browserupgrade">You are using an <strong>outdated</strong> browser. Please <a
  href="https://browsehappy.com/">upgrade your browser</a> to improve your experience and security.</p>
<![endif]*/}
  {/********************************* Code Start From Here ******************************** */}
  {/*==============================Preloader==============================*/}
  {/*============================== Sidemenu============================== */}
  <div className="sidemenu-wrapper d-none d-lg-block ">
    <div className="sidemenu-content">
      <button className="closeButton sideMenuCls">
        <i className="far fa-times" />
      </button>
      <div className="widget footer-widget">
        <div className="as-widget-about">
          <div className="about-logo">
            <a href="/">
              <img
                src="https://www.faxtonlimited.com/landing/img/fico.png"
                alt="Faxton"
              />
            </a>
          </div>
          <p className="about-text">
            We specialize in trading and managing accounts and funds for her
            investors; ensuring that investors get their promised returns on
            investment. A few years ago the platform specially traded for a few
            employed members of the sponsor organisations and kept pushing to
            take it global.
          </p>
          <h4 className="footer-info-title">Follow Us On:</h4>
          <div className="as-social author-social">
            <a href="https://facebook.com/">
              <i className="fab fa-facebook-f" />
            </a>
            <a href="https://twitter.com/">
              <i className="fab fa-twitter" />
            </a>
            <a href="https://linkedin.com/">
              <i className="fab fa-linkedin-in" />
            </a>
            <a href="https://instagram.com/">
              <i className="fab fa-instagram" />
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div className="popup-search-box d-none d-lg-block">
    <button className="searchClose">
      <i className="fal fa-times" />
    </button>
    <form action="#">
      <input
        type="text"
        placeholder="What are you looking for?"
        data-ddg-inputtype="unknown"
      />
      <button type="submit">
        <i className="fal fa-search" />
      </button>
    </form>
  </div>
  {/*============================== Mobile Menu ============================== */}
  <div className="as-menu-wrapper">
    <div className="as-menu-area text-center">
      <button className="as-menu-toggle">
        <i className="fal fa-times" />
      </button>
      <div className="mobile-logo">
        <a href="/">
          <img
            src="https://www.faxtonlimited.com/front/images/logo.png"
            alt="Faxton"
            style={{ width: 80, height: 80 }}
          />
        </a>
      </div>
      <div className="as-mobile-menu">
        <ul>
          <li className="active">
            <a href="/">Home</a>
          </li>
          <li className="">
            <a href="/about">About</a>
          </li>
          <li className="">
            <a href="/services">Services</a>
          </li>
          <li className="">
            <a href="/pricing">Pricing</a>
          </li>
          <li className="">
            <a href="/markets">Market</a>
          </li>
          <li className="">
            <a href="/compounding">Compounding</a>
          </li>
          <li className="">
            <a href="/contact">Contact</a>
          </li>
          <li className="menu-item-has-children as-item-has-children">
            <a href="#">
              Account
              <span className="as-mean-expand" />
            </a>
            <ul className="sub-menu as-submenu" style={{ display: "none" }}>
              <li>
                <a href="/register">Register</a>
              </li>
              <li>
                <a href="/login">Login</a>
              </li>
            </ul>
          </li>
          <li id="google_translate_element">
            <div className="skiptranslate goog-te-gadget" dir="ltr" style={{}}>
              <div id=":0.targetLanguage">
                <select
                  className="goog-te-combo"
                  aria-label="Language Translate Widget"
                  data-ddg-inputtype="unknown"
                >
                  <option value="">Select Language</option>
                  <option value="af">Afrikaans</option>
                  <option value="sq">Albanian</option>
                  <option value="am">Amharic</option>
                  <option value="ar">Arabic</option>
                  <option value="hy">Armenian</option>
                  <option value="as">Assamese</option>
                  <option value="ay">Aymara</option>
                  <option value="az">Azerbaijani</option>
                  <option value="bm">Bambara</option>
                  <option value="eu">Basque</option>
                  <option value="be">Belarusian</option>
                  <option value="bn">Bengali</option>
                  <option value="bho">Bhojpuri</option>
                  <option value="bs">Bosnian</option>
                  <option value="bg">Bulgarian</option>
                  <option value="ca">Catalan</option>
                  <option value="ceb">Cebuano</option>
                  <option value="ny">Chichewa</option>
                  <option value="zh-CN">Chinese (Simplified)</option>
                  <option value="zh-TW">Chinese (Traditional)</option>
                  <option value="co">Corsican</option>
                  <option value="hr">Croatian</option>
                  <option value="cs">Czech</option>
                  <option value="da">Danish</option>
                  <option value="dv">Dhivehi</option>
                  <option value="doi">Dogri</option>
                  <option value="nl">Dutch</option>
                  <option value="eo">Esperanto</option>
                  <option value="et">Estonian</option>
                  <option value="ee">Ewe</option>
                  <option value="tl">Filipino</option>
                  <option value="fi">Finnish</option>
                  <option value="fr">French</option>
                  <option value="fy">Frisian</option>
                  <option value="gl">Galician</option>
                  <option value="ka">Georgian</option>
                  <option value="de">German</option>
                  <option value="el">Greek</option>
                  <option value="gn">Guarani</option>
                  <option value="gu">Gujarati</option>
                  <option value="ht">Haitian Creole</option>
                  <option value="ha">Hausa</option>
                  <option value="haw">Hawaiian</option>
                  <option value="iw">Hebrew</option>
                  <option value="hi">Hindi</option>
                  <option value="hmn">Hmong</option>
                  <option value="hu">Hungarian</option>
                  <option value="is">Icelandic</option>
                  <option value="ig">Igbo</option>
                  <option value="ilo">Ilocano</option>
                  <option value="id">Indonesian</option>
                  <option value="ga">Irish Gaelic</option>
                  <option value="it">Italian</option>
                  <option value="ja">Japanese</option>
                  <option value="jw">Javanese</option>
                  <option value="kn">Kannada</option>
                  <option value="kk">Kazakh</option>
                  <option value="km">Khmer</option>
                  <option value="rw">Kinyarwanda</option>
                  <option value="gom">Konkani</option>
                  <option value="ko">Korean</option>
                  <option value="kri">Krio</option>
                  <option value="ku">Kurdish (Kurmanji)</option>
                  <option value="ckb">Kurdish (Sorani)</option>
                  <option value="ky">Kyrgyz</option>
                  <option value="lo">Lao</option>
                  <option value="la">Latin</option>
                  <option value="lv">Latvian</option>
                  <option value="ln">Lingala</option>
                  <option value="lt">Lithuanian</option>
                  <option value="lg">Luganda</option>
                  <option value="lb">Luxembourgish</option>
                  <option value="mk">Macedonian</option>
                  <option value="mai">Maithili</option>
                  <option value="mg">Malagasy</option>
                  <option value="ms">Malay</option>
                  <option value="ml">Malayalam</option>
                  <option value="mt">Maltese</option>
                  <option value="mi">Maori</option>
                  <option value="mr">Marathi</option>
                  <option value="mni-Mtei">Meiteilon (Manipuri)</option>
                  <option value="lus">Mizo</option>
                  <option value="mn">Mongolian</option>
                  <option value="my">Myanmar (Burmese)</option>
                  <option value="ne">Nepali</option>
                  <option value="no">Norwegian</option>
                  <option value="or">Odia (Oriya)</option>
                  <option value="om">Oromo</option>
                  <option value="ps">Pashto</option>
                  <option value="fa">Persian</option>
                  <option value="pl">Polish</option>
                  <option value="pt">Portuguese</option>
                  <option value="pa">Punjabi</option>
                  <option value="qu">Quechua</option>
                  <option value="ro">Romanian</option>
                  <option value="ru">Russian</option>
                  <option value="sm">Samoan</option>
                  <option value="sa">Sanskrit</option>
                  <option value="gd">Scots Gaelic</option>
                  <option value="nso">Sepedi</option>
                  <option value="sr">Serbian</option>
                  <option value="st">Sesotho</option>
                  <option value="sn">Shona</option>
                  <option value="sd">Sindhi</option>
                  <option value="si">Sinhala</option>
                  <option value="sk">Slovak</option>
                  <option value="sl">Slovenian</option>
                  <option value="so">Somali</option>
                  <option value="es">Spanish</option>
                  <option value="su">Sundanese</option>
                  <option value="sw">Swahili</option>
                  <option value="sv">Swedish</option>
                  <option value="tg">Tajik</option>
                  <option value="ta">Tamil</option>
                  <option value="tt">Tatar</option>
                  <option value="te">Telugu</option>
                  <option value="th">Thai</option>
                  <option value="ti">Tigrinya</option>
                  <option value="ts">Tsonga</option>
                  <option value="tr">Turkish</option>
                  <option value="tk">Turkmen</option>
                  <option value="ak">Twi</option>
                  <option value="uk">Ukrainian</option>
                  <option value="ur">Urdu</option>
                  <option value="ug">Uyghur</option>
                  <option value="uz">Uzbek</option>
                  <option value="vi">Vietnamese</option>
                  <option value="cy">Welsh</option>
                  <option value="xh">Xhosa</option>
                  <option value="yi">Yiddish</option>
                  <option value="yo">Yoruba</option>
                  <option value="zu">Zulu</option>
                </select>
              </div>
              Powered by{" "}
              <span style={{ whiteSpace: "nowrap" }}>
                <a
                  className="VIpgJd-ZVi9od-l4eHX-hSRGPd"
                  href="https://translate.google.com"
                  target="_blank"
                >
                  <img
                    src="https://www.gstatic.com/images/branding/googlelogo/1x/googlelogo_color_42x16dp.png"
                    width="37px"
                    height="14px"
                    style={{ paddingRight: 3 }}
                    alt="Google Translate"
                  />
                  Translate
                </a>
              </span>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
  {/*==============================Header Area==============================*/}
  <Navbar/>
  {/*==============================Hero Area==============================*/}
  <div className="as-hero-wrapper hero-1" id="hero">
  <div
    className="hero-slider-2 as-carousel slick-initialized slick-slider"
    data-fade="true"
    data-slide-show={1}
    data-md-slide-show={1}
    data-arrows="true"
    data-xl-arrows="true"
    data-ml-arrows="true"
    data-lg-arrows="true"
  >
    <button type="button" className="slick-prev slick-arrow" style={{}}>
      <i className="far fa-arrow-left" />
    </button>
    <div className="slick-list draggable">
      <div className="slick-track" style={{ opacity: 1, width: 5400 }}>
        <div
          className="as-hero-slide slick-slide slick-current slick-active"
          style={{
            width: 1800,
            position: "relative",
            left: 0,
            top: 0,
            zIndex: 999,
            opacity: 1
          }}
          tabIndex={0}
          data-slick-index={0}
          aria-hidden="false"
        >
          <div
            className="as-hero-bg background-image"
            style={{
              backgroundImage:
                'url("https://www.faxtonlimited.com/landing/images/slider/slide1.jpg")'
            }}
          >
            <div className="hero-shape">
              <img
                src="https://www.faxtonlimited.com/front/assets/img/hero/hero_shape_1.svg"
                alt="shape"
              />
            </div>
          </div>
          <div className="container">
            <div className="hero-style1">
              <span
                className="hero-subtitle hero-subtitle-two slideindown as-animated"
                data-ani="slideindown"
                data-ani-delay="0.1s"
                style={{ animationDelay: "0.1s" }}
              >
                FAXTON INCENTIVE LIMITED <span className="shape" />
              </span>
              <h3
                className="hero-title slideindown as-animated"
                data-ani="slideindown"
                data-ani-delay="0.0s"
                style={{ animationDelay: "0s" }}
              >
                Our Goal is your success <br />
              </h3>
              <p
                className="hero-text mr-50 slideinleft as-animated"
                data-ani="slideinleft"
                data-ani-delay="0.7s"
                style={{ animationDelay: "0.7s" }}
              >
                We have personalized our customer profile to individual
                preferences, this helps us to ensure that they remain our
                priority.
              </p>
              <div
                className="btn-group slideinup pt-40 slideinleft as-animated"
                data-ani="slideinleft"
                data-ani-delay="0.9s"
                style={{ animationDelay: "0.9s" }}
              >
                <a
                  href="/about"
                  className="as-btn shadow-none"
                  tabIndex={0}
                >
                  Discover More
                </a>{" "}
                <a
                  href="/register"
                  className="as-btn border-btn shadow-none"
                  tabIndex={0}
                >
                  Join Us
                </a>
              </div>
            </div>
          </div>
        </div>
        <div
          className="as-hero-slide slick-slide"
          style={{
            width: 1800,
            position: "relative",
            left: "-1800px",
            top: 0,
            zIndex: 998,
            opacity: 0,
            transition: "opacity 1000ms ease 0s"
          }}
          tabIndex={-1}
          data-slick-index={1}
          aria-hidden="true"
        >
          <div
            className="as-hero-bg background-image"
            style={{
              backgroundImage:
                'url("https://www.faxtonlimited.com/landing/images/slider/slide2.jpg")'
            }}
          >
            <div className="hero-shape">
              <img
                src="https://www.faxtonlimited.com/front/assets/img/hero/hero_shape_1.svg"
                alt="shape"
              />
            </div>
          </div>
          <div className="container">
            <div className="hero-style1">
              <span
                className="hero-subtitle hero-subtitle-two slideindown"
                data-ani="slideindown"
                data-ani-delay="0.1s"
                style={{ animationDelay: "0.1s" }}
              >
                FINANCIAL GROWTH
                <span className="shape" />
              </span>
              <h1
                className="hero-title slideindown"
                data-ani="slideindown"
                data-ani-delay="0.0s"
                style={{ animationDelay: "0s" }}
              >
                Grow Your Wealth
                <br />{" "}
              </h1>
              <p
                className="hero-text mr-50 slideinleft"
                data-ani="slideinleft"
                data-ani-delay="0.7s"
                style={{ animationDelay: "0.7s" }}
              >
                Discover how best to grow, manage and invest your money to fund
                your life ambitions.
              </p>
              <div
                className="btn-group slideinup pt-40 slideinleft"
                data-ani="slideinleft"
                data-ani-delay="0.9s"
                style={{ animationDelay: "0.9s" }}
              >
                <a
                  href="/about"
                  className="as-btn shadow-none"
                  tabIndex={-1}
                >
                  Discover More
                </a>{" "}
                <a
                  href="/register"
                  className="as-btn border-btn shadow-none"
                  tabIndex={-1}
                >
                  Join Us
                </a>
              </div>
            </div>
          </div>
        </div>
        <div
          className="as-hero-slide slick-slide"
          style={{
            width: 1800,
            position: "relative",
            left: "-3600px",
            top: 0,
            zIndex: 998,
            opacity: 0,
            transition: "opacity 1000ms ease 0s"
          }}
          tabIndex={-1}
          data-slick-index={2}
          aria-hidden="true"
        >
          <div
            className="as-hero-bg background-image"
            style={{
              backgroundImage:
                'url("https://www.faxtonlimited.com/landing/images/slider/slide4.jpg")'
            }}
          >
            <div className="hero-shape">
              <img
                src="https://www.faxtonlimited.com/front/assets/img/hero/hero_shape_1.svg"
                alt="shape"
              />
            </div>
          </div>
          <div className="container">
            <div className="hero-style1">
              <span
                className="hero-subtitle hero-subtitle-two slideindown"
                data-ani="slideindown"
                data-ani-delay="0.1s"
                style={{ animationDelay: "0.1s" }}
              >
                HERE TO SERVE
                <span className="shape" />
              </span>
              <h1
                className="hero-title slideindown"
                data-ani="slideindown"
                data-ani-delay="0.0s"
                style={{ animationDelay: "0s" }}
              >
                Premium Service
                <br /> Investment Management.
              </h1>
              <p
                className="hero-text mr-50 slideinleft"
                data-ani="slideinleft"
                data-ani-delay="0.7s"
                style={{ animationDelay: "0.7s" }}
              >
                We cater to the unique investment goals of our clients through a
                combination of specialized bespoke products, services and
                professional expertise.
              </p>
              <div
                className="btn-group slideinup pt-40 slideinleft"
                data-ani="slideinleft"
                data-ani-delay="0.9s"
                style={{ animationDelay: "0.9s" }}
              >
                <a
                  href="/about"
                  className="as-btn shadow-none"
                  tabIndex={-1}
                >
                  Discover More
                </a>{" "}
                <a
                  href="/register"
                  className="as-btn border-btn shadow-none"
                  tabIndex={-1}
                >
                  Join Us
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <button type="button" className="slick-next slick-arrow" style={{}}>
      <i className="far fa-arrow-right" />
    </button>
  </div>
</div>

  {/*==============================Service Area ==============================*/}
  <section className="service-sec" id="service-sec">
    <div className="container">
      <div className="service-wrapper style-1">
        <div className="service-box">
          <div className="service-box_inner">
            <div
              className="service-box_icon background-image"
              style={{
                backgroundImage:
                  'url("https://www.faxtonlimited.com/front/assets/img/icon/security.png")'
              }}
            />
            <div className="service-content">
              <h3 className="service-box_title">
                <a href="#">Security</a>
              </h3>
              <p className="service-box_text">
                We have invested in the advance SSL technology to prevent
                fraudulent acts like data breach on your private information. We
                will never disclose them without consensual agreement.
              </p>
            </div>
          </div>
        </div>
        <div className="service-box active">
          <div className="service-box_inner">
            <div
              className="service-box_icon background-image"
              style={{
                backgroundImage:
                  'url("https://www.faxtonlimited.com/front/assets/img/icon/integrity.png")'
              }}
            />
            <div className="service-content">
              <h3 className="service-box_title">
                <a href="#">Integrity</a>
              </h3>
              <p className="service-box_text">
                Integrity is the soul of business. Our full-fledged contribution
                towards the financial growth of the community by customer
                service providers ensure that our clients with are satisfied.
              </p>
            </div>
          </div>
        </div>
        <div className="service-box">
          <div className="service-box_inner">
            <div
              className="service-box_icon background-image"
              style={{
                backgroundImage:
                  'url("https://www.faxtonlimited.com/front/assets/img/icon/checklist.png")'
              }}
            />
            <div className="service-content">
              <h3 className="service-box_title">
                <a href="#">Accountability</a>
              </h3>
              <p className="service-box_text">
                We can only reward the unwavering trust of our clients by
                offering them top-quality accounting services. We analyze and
                manage risks then, we make decisions we are proud of.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  {/*==============================About Area ==============================*/}
  <div className="space about-section" id="about-sec">
    <div className="container">
      <div className="row align-items-center">
        <div className="col-xl-6">
          <div className="img-box1 pe-xl-4">
            <div className="img1">
              <img
                className="image_1"
                src="https://www.faxtonlimited.com/landing/images/about copy.jpg"
                alt="About"
              />
              <img
                className="shape_1"
                src="https://www.faxtonlimited.com/front/assets/img/icon/rectangle.svg"
                alt="About"
              />
              <img
                className="shape_2"
                src="https://www.faxtonlimited.com/front/assets/img/icon/shape_1.svg"
                alt="About"
              />
              <div className="about-date">
                <p>
                  Started Journey <span>1997</span>
                </p>
              </div>
              <div className="about-date about-date-two">
                <h2 className="counter-card_number">
                  <span className="counter-number">27</span>K+
                </h2>
                <p className="counter-card_text"> Clients Actives</p>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-6">
          <div className="ps-xl-4">
            <div className="title-area mb-25 mr-40">
              <span className="sub-title">Who We Are</span>
              <h2 className="sec-title">A Secure and Transparent Platform.</h2>
              <p className="mt-n2 mb-25 text-justify">
                Faxton Limited founded in 1997, has emerged as a leading
                investment management firm. Our doctrine is to provide maximum
                value to our esteemed clients, and we upheld to it every day. As
                a company we focus on giving quality financial services to our
                clients (corporations, financial institutions, governments and
                individuals). Albeit with slight alterations as the years have
                gone by. Over the years we have improved significantly at a
                lightening pace. We always have become an object of reference
                for our competitors when it comes to providing quality financialx
                (investment) services.
              </p>
            </div>
            <div className="about-wrapper">
              <div className="about-image">
                <img
                  src="https://www.faxtonlimited.com/landing/images/ss.png"
                  alt=""
                />
                <a
                  href="https://www.faxtonlimited.com/landing/Kyc.mp4"
                  className="video-btn popup-video"
                >
                  <div className="play-btn">
                    <i className="fa-sharp fa-solid fa-play" />
                  </div>
                </a>
              </div>
              <p className="mr-10 text-justify">
                The contribution of unlisted assets has enabled us to build a
                first-rate track record over the past years. In a context of
                extremely high risk rates and volatile financial markets
              </p>
            </div>
            <hr className="style1" />
            <div className="btn-group style2">
              <a
                href="/about"
                className="as-btn"
              >
                About More
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div>
    <div className="container">
      <div
        className="counter-sec-space background-image"
        style={{ backgroundImage: 'url("assets/img/bg/counter_bg_1.png")' }}
      >
        <div className="row gy-40 justify-content-center">
          <div className="col-sm-6 col-lg-3">
            <div className="counter-card style3">
              <h2 className="counter-card_number">
                <span className="counter-number">500</span>M+
              </h2>
              <p className="counter-card_text">Trading Capital</p>
            </div>
          </div>
          <div className="col-sm-6 col-lg-3">
            <div className="counter-card style3">
              <h2 className="counter-card_number">
                <span className="counter-number">50</span>M+
              </h2>
              <p className="counter-card_text">Exchange Per Day</p>
            </div>
          </div>
          <div className="col-sm-6 col-lg-3">
            <div className="counter-card style3">
              <h2 className="counter-card_number">
                <span className="counter-number">27</span>k+
              </h2>
              <p className="counter-card_text">Happy Clients</p>
            </div>
          </div>
          <div className="col-sm-6 col-lg-3">
            <div className="counter-card style3">
              <h2 className="counter-card_number">
                <span className="counter-number">160</span>
              </h2>
              <p className="counter-card_text">Countries supported</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  {/*==============================Event Area ==============================*/}
  <section
    className="space background-image arrow-wrap"
    style={{
      backgroundImage:
        'url("https://www.faxtonlimited.com/front/assets/img/bg/service_bg.jpg")'
    }}
  >
    <div className="container">
      <div className="row align-items-center">
        <div className="col-lg-6">
          <div className="title-area">
            <span className="sub-title">Our Services</span>
            <h2 className="sec-title">What we Do</h2>
            <p className="mt-n2 mb-35 mr-n1">
              Globally investing in the fields of high profit yield and cash
              returns.
            </p>
          </div>
        </div>
        <div className="col-lg-6">
          <div className="btn-group style1">
            <a href="/services" className="as-btn">
              View All Service
            </a>
          </div>
        </div>
      </div>
      <div
        className="row slider-shadow as-carousel slick-initialized slick-slider"
        data-slide-show={3}
        data-lg-slide-show={3}
        data-md-slide-show={2}
        data-sm-slide-show={1}
        data-xs-slide-show={1}
        data-arrows="true"
      >
        <div className="slick-list draggable">
          <div
            className="slick-track"
            style={{
              opacity: 1,
              width: 4160,
              transform: "translate3d(-1280px, 0px, 0px)"
            }}
          >
            <div
              className="col-lg-4 col-md-6 slick-slide slick-cloned"
              style={{ width: 320 }}
              tabIndex={-1}
              data-slick-index={-3}
              id=""
              aria-hidden="true"
            >
              <div className="event-card">
                <div className="event-card_img">
                  {" "}
                  <img
                    src="https://www.faxtonlimited.com/front/images/forex.jpeg"
                    alt="event"
                  />{" "}
                </div>
                <div className="event-content">
                  <div
                    className="event-overlay background-image"
                    style={{
                      backgroundImage:
                        'url("https://www.faxtonlimited.com/front/assets/img/bg/service_card_overlay.png")'
                    }}
                  />
                  <div className="event-card_shape">
                    {" "}
                    <img
                      src="https://www.faxtonlimited.com/front/assets/img/shape/bandit.svg"
                      alt="Shape"
                    />{" "}
                  </div>
                  <h3 className="event-card_title">
                    <a href="#" tabIndex={-1}>
                      STOCK AND OTHER COMMODITIES TRADING
                    </a>
                  </h3>
                  <p className="event-card_text">
                    Stocks and commodities are two very different types of
                    investments, though both are traded on open exchanges most
                    weekdays. Stock investing involves buying and selling of
                    shares in corporations.
                  </p>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 slick-slide slick-cloned"
              style={{ width: 320 }}
              tabIndex={-1}
              data-slick-index={-2}
              id=""
              aria-hidden="true"
            >
              <div className="event-card">
                <div className="event-card_img">
                  {" "}
                  <img
                    src="https://www.faxtonlimited.com/landing/img/04.jpeg"
                    alt="event"
                  />{" "}
                </div>
                <div className="event-content">
                  <div
                    className="event-overlay background-image"
                    style={{
                      backgroundImage:
                        'url("https://www.faxtonlimited.com/front/assets/img/bg/service_card_overlay.png")'
                    }}
                  />
                  <div className="event-card_shape">
                    {" "}
                    <img
                      src="https://www.faxtonlimited.com/front/assets/img/shape/employee.svg"
                      alt="Shape"
                    />{" "}
                  </div>
                  {/* <div class="shape_overlay"></div> */}
                  <h3 className="event-card_title">
                    <a
                      href="/cannabis"
                      tabIndex={-1}
                    >
                      CANNABIS INVESTING
                    </a>
                  </h3>
                  <p className="event-card_text">
                    Cannabis has begun to gain wider acceptance and has been
                    legalized in a growing number of nations, states, and other
                    jurisdictions for recreational, medicinal and other uses.
                  </p>{" "}
                  <a
                    href="/cannabis"
                    className="line-btn"
                    tabIndex={-1}
                  >
                    Read More
                  </a>{" "}
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 slick-slide slick-cloned"
              style={{ width: 320 }}
              tabIndex={-1}
              data-slick-index={-1}
              id=""
              aria-hidden="true"
            >
              <div className="event-card">
                <div className="event-card_img">
                  {" "}
                  <img
                    src="https://www.faxtonlimited.com/landing/img/ss.jpeg"
                    alt="event"
                  />{" "}
                </div>
                <div className="event-content">
                  <div
                    className="event-overlay background-image"
                    style={{
                      backgroundImage:
                        'url("https://www.faxtonlimited.com/front/assets/img/bg/service_card_overlay.png")'
                    }}
                  />
                  <div className="event-card_shape">
                    {" "}
                    <img
                      src="https://www.faxtonlimited.com/front/assets/img/shape/balance.svg"
                      alt="Shape"
                    />{" "}
                  </div>
                  {/* <div class="shape_overlay"></div> */}
                  <h3 className="event-card_title">
                    <a
                      href="/renewableenergy"
                      tabIndex={-1}
                    >
                      RENEWABLE ENERGY FOR SUPERCHARGERS
                    </a>
                  </h3>
                  <p className="event-card_text">
                    The rise of electric vehicles (EVs) has led to an increased
                    demand for energy sources that are both sustainable and
                    reliable. As a result, renewable energy has become a popular
                    option for powering EVs.
                  </p>{" "}
                  <a
                    href="/renewableenergy"
                    className="line-btn"
                    tabIndex={-1}
                  >
                    Read More
                  </a>{" "}
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 slick-slide"
              style={{ width: 320 }}
              tabIndex={-1}
              data-slick-index={0}
              aria-hidden="true"
            >
              <div className="event-card">
                <div className="event-card_img">
                  {" "}
                  <img
                    src="https://www.faxtonlimited.com/landing/img/01.jpeg"
                    alt="event"
                  />{" "}
                </div>
                <div className="event-content">
                  <div
                    className="event-overlay background-image"
                    style={{
                      backgroundImage:
                        'url("https://www.faxtonlimited.com/front/assets/img/bg/service_card_overlay.png")'
                    }}
                  />
                  <div className="event-card_shape">
                    <img
                      src="https://www.faxtonlimited.com/front/assets/img/shape/bandit.svg"
                      alt="Shape"
                    />
                  </div>
                  <div className="event-icon-overlay" />
                  <h3 className="event-card_title">
                    <a
                      href="/crypto"
                      tabIndex={-1}
                    >
                      CRYPTO CURRENCY TRADING
                    </a>
                  </h3>
                  <p className="event-card_text">
                    Cryptocurrencies are encrypted decentralised digital
                    currencies that are transferred between individuals. These
                    currencies are not tangible and exist only in the electronic
                    from, it is a digital asset that exists and remains as data.
                  </p>
                  <a
                    href="/crypto"
                    className="line-btn"
                    tabIndex={-1}
                  >
                    Read More
                  </a>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 slick-slide slick-current slick-active"
              style={{ width: 320 }}
              tabIndex={0}
              data-slick-index={1}
              aria-hidden="false"
            >
              <div className="event-card">
                <div className="event-card_img">
                  {" "}
                  <img
                    src="https://www.faxtonlimited.com/landing/img/02.jpeg"
                    alt="event"
                  />{" "}
                </div>
                <div className="event-content">
                  <div
                    className="event-overlay background-image"
                    style={{
                      backgroundImage:
                        'url("https://www.faxtonlimited.com/front/assets/img/bg/service_card_overlay.png")'
                    }}
                  />
                  <div className="event-card_shape">
                    {" "}
                    <img
                      src="https://www.faxtonlimited.com/front/assets/img/shape/balance.svg"
                      alt="Shape"
                    />{" "}
                  </div>
                  <h3 className="event-card_title">
                    <a href="/forex" tabIndex={0}>
                      FOREX TRADING
                    </a>
                  </h3>
                  <p className="event-card_text">
                    Placing a trade in the foreign exchange market is simple.
                    The mechanics of a trade are very similar to those found in
                    other financial markets (like the stock market), so if you
                    have any experience in trading, you should be able to pick
                    it up pretty quickly.
                  </p>
                  <a
                    href="/forex"
                    className="line-btn"
                    tabIndex={0}
                  >
                    Read More
                  </a>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 slick-slide slick-active"
              style={{ width: 320 }}
              tabIndex={0}
              data-slick-index={2}
              aria-hidden="false"
            >
              <div className="event-card">
                <div className="event-card_img">
                  {" "}
                  <img
                    src="https://www.faxtonlimited.com/front/images/forex.jpeg"
                    alt="event"
                  />{" "}
                </div>
                <div className="event-content">
                  <div
                    className="event-overlay background-image"
                    style={{
                      backgroundImage:
                        'url("https://www.faxtonlimited.com/front/assets/img/bg/service_card_overlay.png")'
                    }}
                  />
                  <div className="event-card_shape">
                    {" "}
                    <img
                      src="https://www.faxtonlimited.com/front/assets/img/shape/bandit.svg"
                      alt="Shape"
                    />{" "}
                  </div>
                  <h3 className="event-card_title">
                    <a href="#" tabIndex={0}>
                      STOCK AND OTHER COMMODITIES TRADING
                    </a>
                  </h3>
                  <p className="event-card_text">
                    Stocks and commodities are two very different types of
                    investments, though both are traded on open exchanges most
                    weekdays. Stock investing involves buying and selling of
                    shares in corporations.
                  </p>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 slick-slide slick-active"
              style={{ width: 320 }}
              tabIndex={0}
              data-slick-index={3}
              aria-hidden="false"
            >
              <div className="event-card">
                <div className="event-card_img">
                  {" "}
                  <img
                    src="https://www.faxtonlimited.com/landing/img/04.jpeg"
                    alt="event"
                  />{" "}
                </div>
                <div className="event-content">
                  <div
                    className="event-overlay background-image"
                    style={{
                      backgroundImage:
                        'url("https://www.faxtonlimited.com/front/assets/img/bg/service_card_overlay.png")'
                    }}
                  />
                  <div className="event-card_shape">
                    {" "}
                    <img
                      src="https://www.faxtonlimited.com/front/assets/img/shape/employee.svg"
                      alt="Shape"
                    />{" "}
                  </div>
                  {/* <div class="shape_overlay"></div> */}
                  <h3 className="event-card_title">
                    <a
                      href="/cannabis"
                      tabIndex={0}
                    >
                      CANNABIS INVESTING
                    </a>
                  </h3>
                  <p className="event-card_text">
                    Cannabis has begun to gain wider acceptance and has been
                    legalized in a growing number of nations, states, and other
                    jurisdictions for recreational, medicinal and other uses.
                  </p>{" "}
                  <a
                    href="/cannabis"
                    className="line-btn"
                    tabIndex={0}
                  >
                    Read More
                  </a>{" "}
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 slick-slide"
              style={{ width: 320 }}
              tabIndex={-1}
              data-slick-index={4}
              aria-hidden="true"
            >
              <div className="event-card">
                <div className="event-card_img">
                  {" "}
                  <img
                    src="https://www.faxtonlimited.com/landing/img/ss.jpeg"
                    alt="event"
                  />{" "}
                </div>
                <div className="event-content">
                  <div
                    className="event-overlay background-image"
                    style={{
                      backgroundImage:
                        'url("https://www.faxtonlimited.com/front/assets/img/bg/service_card_overlay.png")'
                    }}
                  />
                  <div className="event-card_shape">
                    {" "}
                    <img
                      src="https://www.faxtonlimited.com/front/assets/img/shape/balance.svg"
                      alt="Shape"
                    />{" "}
                  </div>
                  {/* <div class="shape_overlay"></div> */}
                  <h3 className="event-card_title">
                    <a
                      href="/renewableenergy"
                      tabIndex={-1}
                    >
                      RENEWABLE ENERGY FOR SUPERCHARGERS
                    </a>
                  </h3>
                  <p className="event-card_text">
                    The rise of electric vehicles (EVs) has led to an increased
                    demand for energy sources that are both sustainable and
                    reliable. As a result, renewable energy has become a popular
                    option for powering EVs.
                  </p>{" "}
                  <a
                    href="/renewableenergy"
                    className="line-btn"
                    tabIndex={-1}
                  >
                    Read More
                  </a>{" "}
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 slick-slide slick-cloned"
              style={{ width: 320 }}
              tabIndex={-1}
              data-slick-index={5}
              id=""
              aria-hidden="true"
            >
              <div className="event-card">
                <div className="event-card_img">
                  {" "}
                  <img
                    src="https://www.faxtonlimited.com/landing/img/01.jpeg"
                    alt="event"
                  />{" "}
                </div>
                <div className="event-content">
                  <div
                    className="event-overlay background-image"
                    style={{
                      backgroundImage:
                        'url("https://www.faxtonlimited.com/front/assets/img/bg/service_card_overlay.png")'
                    }}
                  />
                  <div className="event-card_shape">
                    <img
                      src="https://www.faxtonlimited.com/front/assets/img/shape/bandit.svg"
                      alt="Shape"
                    />
                  </div>
                  <div className="event-icon-overlay" />
                  <h3 className="event-card_title">
                    <a
                      href="/crypto"
                      tabIndex={-1}
                    >
                      CRYPTO CURRENCY TRADING
                    </a>
                  </h3>
                  <p className="event-card_text">
                    Cryptocurrencies are encrypted decentralised digital
                    currencies that are transferred between individuals. These
                    currencies are not tangible and exist only in the electronic
                    from, it is a digital asset that exists and remains as data.
                  </p>
                  <a
                    href="/crypto"
                    className="line-btn"
                    tabIndex={-1}
                  >
                    Read More
                  </a>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 slick-slide slick-cloned"
              style={{ width: 320 }}
              tabIndex={-1}
              data-slick-index={6}
              id=""
              aria-hidden="true"
            >
              <div className="event-card">
                <div className="event-card_img">
                  {" "}
                  <img
                    src="https://www.faxtonlimited.com/landing/img/02.jpeg"
                    alt="event"
                  />{" "}
                </div>
                <div className="event-content">
                  <div
                    className="event-overlay background-image"
                    style={{
                      backgroundImage:
                        'url("https://www.faxtonlimited.com/front/assets/img/bg/service_card_overlay.png")'
                    }}
                  />
                  <div className="event-card_shape">
                    {" "}
                    <img
                      src="https://www.faxtonlimited.com/front/assets/img/shape/balance.svg"
                      alt="Shape"
                    />{" "}
                  </div>
                  <h3 className="event-card_title">
                    <a href="/forex" tabIndex={-1}>
                      FOREX TRADING
                    </a>
                  </h3>
                  <p className="event-card_text">
                    Placing a trade in the foreign exchange market is simple.
                    The mechanics of a trade are very similar to those found in
                    other financial markets (like the stock market), so if you
                    have any experience in trading, you should be able to pick
                    it up pretty quickly.
                  </p>
                  <a
                    href="/forex"
                    className="line-btn"
                    tabIndex={-1}
                  >
                    Read More
                  </a>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 slick-slide slick-cloned"
              style={{ width: 320 }}
              tabIndex={-1}
              data-slick-index={7}
              id=""
              aria-hidden="true"
            >
              <div className="event-card">
                <div className="event-card_img">
                  {" "}
                  <img
                    src="https://www.faxtonlimited.com/front/images/forex.jpeg"
                    alt="event"
                  />{" "}
                </div>
                <div className="event-content">
                  <div
                    className="event-overlay background-image"
                    style={{
                      backgroundImage:
                        'url("https://www.faxtonlimited.com/front/assets/img/bg/service_card_overlay.png")'
                    }}
                  />
                  <div className="event-card_shape">
                    {" "}
                    <img
                      src="https://www.faxtonlimited.com/front/assets/img/shape/bandit.svg"
                      alt="Shape"
                    />{" "}
                  </div>
                  <h3 className="event-card_title">
                    <a href="#" tabIndex={-1}>
                      STOCK AND OTHER COMMODITIES TRADING
                    </a>
                  </h3>
                  <p className="event-card_text">
                    Stocks and commodities are two very different types of
                    investments, though both are traded on open exchanges most
                    weekdays. Stock investing involves buying and selling of
                    shares in corporations.
                  </p>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 slick-slide slick-cloned"
              style={{ width: 320 }}
              tabIndex={-1}
              data-slick-index={8}
              id=""
              aria-hidden="true"
            >
              <div className="event-card">
                <div className="event-card_img">
                  {" "}
                  <img
                    src="https://www.faxtonlimited.com/landing/img/04.jpeg"
                    alt="event"
                  />{" "}
                </div>
                <div className="event-content">
                  <div
                    className="event-overlay background-image"
                    style={{
                      backgroundImage:
                        'url("https://www.faxtonlimited.com/front/assets/img/bg/service_card_overlay.png")'
                    }}
                  />
                  <div className="event-card_shape">
                    {" "}
                    <img
                      src="https://www.faxtonlimited.com/front/assets/img/shape/employee.svg"
                      alt="Shape"
                    />{" "}
                  </div>
                  {/* <div class="shape_overlay"></div> */}
                  <h3 className="event-card_title">
                    <a
                      href="/cannabis"
                      tabIndex={-1}
                    >
                      CANNABIS INVESTING
                    </a>
                  </h3>
                  <p className="event-card_text">
                    Cannabis has begun to gain wider acceptance and has been
                    legalized in a growing number of nations, states, and other
                    jurisdictions for recreational, medicinal and other uses.
                  </p>{" "}
                  <a
                    href="/cannabis"
                    className="line-btn"
                    tabIndex={-1}
                  >
                    Read More
                  </a>{" "}
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 slick-slide slick-cloned"
              style={{ width: 320 }}
              tabIndex={-1}
              data-slick-index={9}
              id=""
              aria-hidden="true"
            >
              <div className="event-card">
                <div className="event-card_img">
                  {" "}
                  <img
                    src="https://www.faxtonlimited.com/landing/img/ss.jpeg"
                    alt="event"
                  />{" "}
                </div>
                <div className="event-content">
                  <div
                    className="event-overlay background-image"
                    style={{
                      backgroundImage:
                        'url("https://www.faxtonlimited.com/front/assets/img/bg/service_card_overlay.png")'
                    }}
                  />
                  <div className="event-card_shape">
                    {" "}
                    <img
                      src="https://www.faxtonlimited.com/front/assets/img/shape/balance.svg"
                      alt="Shape"
                    />{" "}
                  </div>
                  {/* <div class="shape_overlay"></div> */}
                  <h3 className="event-card_title">
                    <a
                      href="/renewableenergy"
                      tabIndex={-1}
                    >
                      RENEWABLE ENERGY FOR SUPERCHARGERS
                    </a>
                  </h3>
                  <p className="event-card_text">
                    The rise of electric vehicles (EVs) has led to an increased
                    demand for energy sources that are both sustainable and
                    reliable. As a result, renewable energy has become a popular
                    option for powering EVs.
                  </p>{" "}
                  <a
                    href="/renewableenergy"
                    className="line-btn"
                    tabIndex={-1}
                  >
                    Read More
                  </a>{" "}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section
    className="service-one__home-two space background-image"
    style={{
      backgroundImage:
        'url("https://www.faxtonlimited.com/front/assets/img/bg/testimonial_bg.jpg")'
    }}
  >
    <div className="container">
      <div className="block-title text-center">
        <p>What We’re Offering</p>
        <h2>Our Capabilities</h2>
      </div>
      {/* /.block-title */}
      <div className="row">
        <div className="col-lg-4">
          <div
            className="service-box style3 background-image"
            style={{
              backgroundImage:
                'url("https://www.faxtonlimited.com/front/assets/img/shape/ser_shape1.png")'
            }}
          >
            <div
              className="service_overlay background-image"
              style={{
                backgroundImage:
                  'url("https://www.faxtonlimited.com/front/assets/img/shape/ser_shape1_hover.png")'
              }}
            />
            <div className="service-box_inner">
              <div className="service-content">
                <h3>
                  <a href="#">Manage</a>
                </h3>
              </div>
              <div className="service-content">
                <p>
                  Actively managing money for clients across multiple asset
                  classes. We provide investment opportunities in
                  infrastructure, real estate, agriculture, equities, fixed
                  income, private credit and transportation.
                </p>
                <div className="service-second-right-icon-box">
                  <div className="service-second-right-icon">
                    <i className="flaticon-car-loan" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-4">
          <div
            className="service-box style3 background-image"
            style={{
              backgroundImage:
                'url("https://www.faxtonlimited.com/front/assets/img/shape/ser_shape1.png")'
            }}
          >
            <div
              className="service_overlay background-image"
              style={{
                backgroundImage:
                  'url("https://www.faxtonlimited.com/front/assets/img/shape/ser_shape1_hover.png")'
              }}
            />
            <div className="service-box_inner">
              <div className="service-content">
                <h3>
                  <a href="#">Finance</a>
                </h3>
              </div>
              <div className="service-content">
                <p>
                  Making capital available to finance growth opportunities. We
                  provide bespoke financing to individuals and businesses to
                  help them advance projects and build a better future.
                </p>
                <div className="service-second-right-icon-box">
                  <div className="service-second-right-icon">
                    <i className="flaticon-online-money" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-4">
          <div
            className="counter-card service-box style3 background-image"
            style={{
              backgroundImage:
                'url("https://www.faxtonlimited.com/front/assets/img/shape/ser_shape1.png")'
            }}
          >
            <div
              className="service_overlay background-image"
              style={{
                backgroundImage:
                  'url("https://www.faxtonlimited.com/front/assets/img/shape/ser_shape1_hover.png")'
              }}
            />
            <div className="service-box_inner">
              <div className="service-content">
                <h3>
                  <a href="#">Trade</a>
                </h3>
              </div>
              <div className="service-content">
                <p>
                  Risk solutions and market access for producers, consumers,
                  financial institutions and investors. We provide
                  round-the-clock trading, price-making and distribution across
                  commodities and financial markets.
                </p>
                <div className="service-second-right-icon-box">
                  <div className="service-second-right-icon">
                    <i className="flaticon-property-1" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    {/* /.container */}
  </section>
  <section className="space">
    <div
      className="container"
      style={{ width: "100%"}}
    >
      {/* TradingView Widget BEGIN */}
      <div
        className="tradingview-widget-container"
        style={{ width: 1000, height: 490,overflowX: "scroll"  }}
      >
        <iframe
          allowTransparency="true"
          frameBorder={0}
          src="https://www.tradingview-widget.com/embed-widget/crypto-mkt-screener/?locale=en#%7B%22width%22%3A1000%2C%22height%22%3A490%2C%22defaultColumn%22%3A%22overview%22%2C%22screener_type%22%3A%22crypto_mkt%22%2C%22displayCurrency%22%3A%22USD%22%2C%22colorTheme%22%3A%22light%22%2C%22market%22%3A%22crypto%22%2C%22enableScrolling%22%3Atrue%2C%22utm_source%22%3A%22www.faxtonlimited.com%22%2C%22utm_medium%22%3A%22widget_new%22%2C%22utm_campaign%22%3A%22cryptomktscreener%22%7D"
          title="crypto mkt-screener TradingView widget"
          lang="en"
          style={{
            userSelect: "none",
            boxSizing: "border-box",
            display: "block",
            height: "calc(458px)",
            width: 1000
          }}
        />
        <div className="tradingview-widget-copyright">
          <a
            href="https://www.tradingview.com/markets/cryptocurrencies/prices-all/?utm_source=www.faxtonlimited.com&utm_medium=widget_new&utm_campaign=cryptomktscreener"
            rel="noopener"
            target="_blank"
          >
            <span className="blue-text">Crypto markets</span>
          </a>{" "}
          by TradingView
        </div>
        <style
          dangerouslySetInnerHTML={{
            __html:
              "\n\t.tradingview-widget-copyright {\n\t\tfont-size: 13px !important;\n\t\tline-height: 32px !important;\n\t\ttext-align: center !important;\n\t\tvertical-align: middle !important;\n\t\t/* @mixin sf-pro-display-font; */\n\t\tfont-family: -apple-system, BlinkMacSystemFont, 'Trebuchet MS', Roboto, Ubuntu, sans-serif !important;\n\t\tcolor: #9db2bd !important;\n\t}\n\n\t.tradingview-widget-copyright .blue-text {\n\t\tcolor: #2962FF !important;\n\t}\n\n\t.tradingview-widget-copyright a {\n\t\ttext-decoration: none !important;\n\t\tcolor: #9db2bd !important;\n\t}\n\n\t.tradingview-widget-copyright a:visited {\n\t\tcolor: #9db2bd !important;\n\t}\n\n\t.tradingview-widget-copyright a:hover .blue-text {\n\t\tcolor: #1E53E5 !important;\n\t}\n\n\t.tradingview-widget-copyright a:active .blue-text {\n\t\tcolor: #1848CC !important;\n\t}\n\n\t.tradingview-widget-copyright a:visited .blue-text {\n\t\tcolor: #2962FF !important;\n\t}\n\t"
          }}
        />
      </div>
      {/* TradingView Widget END */}
    </div>
  </section>
  {/*==============================Price Area ==============================*/}
  <section className="section pb-4">
    <div className="container wide-lg" style={{ fontSize: ".875rem" }}>
      <div className="row g-gs">
        <div className="col-lg-8">
          <div className="row g-gs">
            <div className="col-sm-6 col-md-4 mb-2">
              <div className="price-card card-shadow text-center h-100">
                <div className="card-inner p-2">
                  <div className="card-text mt-4">
                    <h6 className="title fs-14px">
                      Register your free account
                    </h6>
                    <p style={{ color: "black" }}>
                      Sign up with your email and get started!
                    </p>
                  </div>
                </div>
                <div className="card-inner py-2 border-top mt-auto">
                  <a
                    className="link"
                    href="/register"
                  >
                    Create an account
                  </a>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-sm-6 mb-2">
              <div className="price-card card-shadow text-center h-100">
                <div className="card-inner p-2">
                  <div className="card-text mt-4">
                    <h6 className="title fs-14px">Deposit fund and invest</h6>
                    <p style={{ color: "black" }}>
                      Just top up your balance &amp; select your desired plan.
                    </p>
                  </div>
                </div>
                <div className="card-inner py-2 border-top mt-auto">
                  <a
                    className="link"
                    href="/register"
                  >
                    Make a deposit
                  </a>
                </div>
              </div>
            </div>
            <div className="col-md-4 mb-2">
              <div className="price-card card-shadow text-center h-100">
                <div className="card-inner p-2">
                  <div className="card-text mt-4">
                    <h6 className="title fs-14px">Payout your profits</h6>
                    <p style={{ color: "black" }}>
                      Withdraw your funds to your account once you earn profit.
                    </p>
                  </div>
                </div>
                <div className="card-inner py-2 border-top mt-auto">
                  <a
                    className="link"
                    href="/register"
                  >
                    Withdraw profits
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-4 mb-2">
          <div className="price-card card-shadow text-center h-100">
            <div className="card-inner p-2">
              <div className="card-text mt-4">
                <h6 className="title fs-14px">Payment processors we accept</h6>
                <p className="pl-1 pr-1" style={{ color: "black" }}>
                  We accept cryptocurrencies such as Bitcoin, Ethereum and
                  Tether.
                </p>
              </div>
            </div>
            <div className="card-inner py-2 border-top mt-auto">
              <a className="link" href="/register">
                Join and Deposit Now
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  {/*==============================Our History Area ==============================*/}
  <div
    className="space-top background-image"
    style={{
      backgroundImage:
        'url("https://www.faxtonlimited.com/front/assets/img/bg/history_bg_1.jpg")'
    }}
  >
    <div
      className="history-bottom background-image"
      style={{
        backgroundImage:
          'url("https://www.faxtonlimited.com/front/assets/img/event/history_bg.png")'
      }}
    >
      <div className="container">
        <div className="row">
          <div className="col-lg-8">
            <div className="history-title">
              <h3>Join The Big Players</h3>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="history-btn">
              <a
                href="/register"
                className="as-btn black-btn shadow-none"
              >
                Join Us
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <section className="section section-feature space">
    <div className="container">
      <div className="row g-gs justify-content-lg-between align-items-center">
        <div className="col-xl-6 col-lg-7">
          <div className="row text-center g-gs pr-lg-4 pr-xl-0 pb-3 pb-lg-0">
            <div className="col-sm-4 col-6">
              <div className="card card-shadow">
                <div className="card-inner">
                  <div className="card-img mb-3">
                    <div className="preview-icon-box card">
                      <div className="preview-icon-wrap">
                        <svg
                          viewBox="0 0 90 90"
                          xmlns="http://www.w3.org/2000/svg"
                          style={{ width: 58 }}
                        >
                          <rect
                            x={9}
                            y={21}
                            width={55}
                            height={29}
                            rx={6}
                            ry={6}
                            fill="#fff"
                            stroke="#139578"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                          />
                          <line
                            x1={17}
                            y1={44}
                            x2={25}
                            y2={44}
                            fill="none"
                            stroke="#3acaaa"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                          />
                          <line
                            x1={30}
                            y1={44}
                            x2={38}
                            y2={44}
                            fill="none"
                            stroke="#3acaaa"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                          />
                          <line
                            x1={42}
                            y1={44}
                            x2={50}
                            y2={44}
                            fill="none"
                            stroke="#3acaaa"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                          />
                          <line
                            x1={17}
                            y1={50}
                            x2={36}
                            y2={50}
                            fill="none"
                            stroke="#3acaaa"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                          />
                          <rect
                            x={16}
                            y={31}
                            width={15}
                            height={8}
                            rx={1}
                            ry={1}
                            fill="#3acaaa"
                          />
                          <path
                            d="M76.79,72.87,32.22,86.73a6,6,0,0,1-7.47-4L17,57.71A6,6,0,0,1,21,50.2L65.52,36.34a6,6,0,0,1,7.48,4l7.73,25.06A6,6,0,0,1,76.79,72.87Z"
                            fill="#fff"
                            stroke="#139578"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                          />
                          <polygon
                            points="75.27 47.3 19.28 64.71 17.14 57.76 73.12 40.35 75.27 47.3"
                            fill="#139578"
                          />
                          <path
                            d="M30,77.65l-1.9-6.79a1,1,0,0,1,.69-1.23l4.59-1.3a1,1,0,0,1,1.23.7l1.9,6.78A1,1,0,0,1,35.84,77l-4.59,1.3A1,1,0,0,1,30,77.65Z"
                            fill="#3acaaa"
                          />
                          <path
                            d="M41.23,74.48l-1.9-6.78A1,1,0,0,1,40,66.47l4.58-1.3a1,1,0,0,1,1.23.69l1.9,6.78A1,1,0,0,1,47,73.88l-4.58,1.29A1,1,0,0,1,41.23,74.48Z"
                            fill="#3acaaa"
                          />
                          <path
                            d="M52.43,71.32l-1.9-6.79a1,1,0,0,1,.69-1.23L55.81,62A1,1,0,0,1,57,62.7l1.9,6.78a1,1,0,0,1-.69,1.23L53.66,72A1,1,0,0,1,52.43,71.32Z"
                            fill="#3acaaa"
                          />
                          <ellipse
                            cx="55.46"
                            cy="19.1"
                            rx="16.04"
                            ry="16.1"
                            fill="#fff"
                            stroke="#139578"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                          />
                          <ellipse
                            cx="55.46"
                            cy="19.1"
                            rx="12.11"
                            ry="12.16"
                            fill="#3acaaa"
                          />
                          <text
                            transform="translate(50.7 23.72) scale(0.99 1)"
                            fontSize="16.12"
                            fill="#139578"
                            fontFamily="Nunito-Black, Nunito Black"
                          >
                            $
                          </text>
                        </svg>
                      </div>
                    </div>
                  </div>
                  <div className="h6">Instant Withdrawals</div>
                </div>
              </div>
              {/* .card */}
            </div>
            {/* .col */}
            <div className="col-sm-4 col-6">
              <div className="card card-full card-shadow">
                <div className="card-inner">
                  <div className="card-img mb-3">
                    <div className="preview-icon-box card">
                      <div className="preview-icon-wrap">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 90 90"
                          style={{ width: 60 }}
                        >
                          <circle
                            cx="24.83"
                            cy="6.55"
                            r="3.06"
                            fill="#3acaaa"
                          />
                          <circle
                            cx="83.97"
                            cy="28.73"
                            r="3.03"
                            fill="none"
                            stroke="#3acaaa"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <circle
                            cx="83.58"
                            cy="67.84"
                            r="1.84"
                            fill="#3acaaa"
                          />
                          <circle
                            cx="12.04"
                            cy="84.67"
                            r="1.84"
                            fill="#3acaaa"
                          />
                          <rect
                            x={3}
                            y="47.84"
                            width="6.12"
                            height="1.75"
                            fill="#3acaaa"
                          />
                          <rect
                            x={3}
                            y="47.84"
                            width="6.12"
                            height="1.75"
                            transform="translate(54.78 42.66) rotate(90)"
                            fill="#3acaaa"
                          />
                          <circle cx="45.76" cy="64.92" r="19.82" fill="#fff" />
                          <circle
                            cx="45.76"
                            cy="64.92"
                            r="18.24"
                            fill="none"
                            stroke="#139578"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                          />
                          <path
                            d="M37.5,22.5V43.89a23.66,23.66,0,0,1,17,.1V22.5Z"
                            fill="#f3f5ff"
                            stroke="#139578"
                            strokeLinecap="round"
                          />
                          <path
                            d="M20.5,22.5v23L27,51.71A23.73,23.73,0,0,1,37.5,43.89V22.5Z"
                            fill="#3acaaa"
                            stroke="#139578"
                            strokeLinecap="round"
                          />
                          <path
                            d="M54.5,22.5V44a23.68,23.68,0,0,1,10.32,7.86l6.68-6.37v-23Z"
                            fill="#3acaaa"
                            stroke="#139578"
                            strokeLinecap="round"
                          />
                          <rect
                            x={12}
                            y={14}
                            width={68}
                            height={8}
                            rx={2}
                            ry={2}
                            fill="none"
                            stroke="#139578"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                          />
                          <path
                            d="M52,58v9.62A1.56,1.56,0,0,1,51.26,69l-4.72,2.73a1.55,1.55,0,0,1-1.56,0L40.25,69a1.55,1.55,0,0,1-.77-1.34V62.19a1.55,1.55,0,0,1,.77-1.35L45,58.11A1.56,1.56,0,0,1,46.39,58v1.62l0,0a1.22,1.22,0,0,0-1.2,0l-3.67,2.12a1.2,1.2,0,0,0-.6,1V67a1.23,1.23,0,0,0,.6,1l3.67,2.11a1.17,1.17,0,0,0,1.2,0L50,68.08a1.21,1.21,0,0,0,.61-1V57.21Z"
                            fill="#139578"
                          />
                          <path
                            d="M49.22,56.4l-1.42-.82v1.63h0V65.8a.52.52,0,0,1-.25.45L46,67.13a.52.52,0,0,1-.51,0L44,66.25a.52.52,0,0,1-.26-.45V64a.51.51,0,0,1,.26-.44l1.53-.89a.52.52,0,0,1,.51,0l.38.22V61.29l-.2-.12a.88.88,0,0,0-.86,0l-2.6,1.5a.85.85,0,0,0-.43.75v3a.85.85,0,0,0,.43.75l2.6,1.5a.83.83,0,0,0,.86,0l2.59-1.5a.86.86,0,0,0,.44-.75V58h0Z"
                            fill="#139578"
                          />
                          <path
                            d="M53.45,58.85v9.41a1.9,1.9,0,0,1-1,1.65l-5.79,3.34a1.92,1.92,0,0,1-1.91,0L39,69.91a1.9,1.9,0,0,1-1-1.65V61.57a1.9,1.9,0,0,1,1-1.65l5.78-3.34a1.92,1.92,0,0,1,1.91,0l1.09.63V55.58l-.91-.53a2.28,2.28,0,0,0-2.26,0l-6.85,4a2.25,2.25,0,0,0-1.13,2v7.91a2.27,2.27,0,0,0,1.13,2l6.85,4a2.28,2.28,0,0,0,2.26,0l6.84-4a2.27,2.27,0,0,0,1.13-2V59.68Z"
                            fill="#139578"
                          />
                        </svg>
                      </div>
                    </div>
                  </div>
                  <div className="h6">Reward Programs</div>
                </div>
              </div>
              {/* .card */}
            </div>
            {/* .col */}
            <div className="col-sm-4 col-6">
              <div className="card card-full card-shadow">
                <div className="card-inner">
                  <div className="card-img mb-3">
                    <div className="preview-icon-box card">
                      <div className="preview-icon-wrap">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 90 90"
                          style={{ width: 60 }}
                        >
                          <rect
                            x="3.5"
                            y={14}
                            width={36}
                            height={62}
                            rx={2}
                            ry={2}
                            fill="#fff"
                            stroke="#139578"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                          />
                          <line
                            x1="3.5"
                            y1={22}
                            x2="39.5"
                            y2={22}
                            fill="none"
                            stroke="#139578"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                          />
                          <line
                            x1="3.5"
                            y1={64}
                            x2="39.5"
                            y2={64}
                            fill="none"
                            stroke="#139578"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                          />
                          <line
                            x1="20.5"
                            y1={18}
                            x2="25.5"
                            y2={18}
                            fill="none"
                            stroke="#3acaaa"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                          />
                          <line
                            x1="17.17"
                            y1={18}
                            x2="17.17"
                            y2={18}
                            fill="none"
                            stroke="#3acaaa"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                          />
                          <circle
                            cx="21.5"
                            cy={70}
                            r={2}
                            fill="none"
                            stroke="#139578"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                          />
                          <rect
                            x="7.5"
                            y={25}
                            width={28}
                            height={35}
                            fill="#eff1ff"
                          />
                          <rect
                            x="10.5"
                            y={40}
                            width={4}
                            height={6}
                            rx={2}
                            ry={2}
                            fill="#3acaaa"
                          />
                          <rect
                            x="16.5"
                            y={40}
                            width={4}
                            height={6}
                            rx={2}
                            ry={2}
                            fill="#3acaaa"
                          />
                          <rect
                            x="22.5"
                            y={40}
                            width={4}
                            height={6}
                            rx={2}
                            ry={2}
                            fill="#3acaaa"
                          />
                          <rect
                            x="28.5"
                            y={40}
                            width={4}
                            height={6}
                            rx={2}
                            ry={2}
                            fill="#3acaaa"
                          />
                          <rect
                            x="50.5"
                            y={14}
                            width={36}
                            height={62}
                            rx={2}
                            ry={2}
                            fill="#fff"
                            stroke="#139578"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                          />
                          <line
                            x1="50.5"
                            y1={22}
                            x2="86.5"
                            y2={22}
                            fill="none"
                            stroke="#139578"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                          />
                          <line
                            x1="50.5"
                            y1={64}
                            x2="86.5"
                            y2={64}
                            fill="none"
                            stroke="#139578"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                          />
                          <line
                            x1="67.5"
                            y1={18}
                            x2="72.5"
                            y2={18}
                            fill="none"
                            stroke="#3acaaa"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                          />
                          <line
                            x1="64.45"
                            y1="17.86"
                            x2="64.45"
                            y2="17.86"
                            fill="none"
                            stroke="#3acaaa"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                          />
                          <circle
                            cx="68.5"
                            cy={70}
                            r={2}
                            fill="none"
                            stroke="#139578"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                          />
                          <rect
                            x="54.5"
                            y={25}
                            width={28}
                            height={35}
                            fill="#eff1ff"
                          />
                          <rect
                            x="57.5"
                            y={39}
                            width={4}
                            height={6}
                            rx={2}
                            ry={2}
                            fill="#3acaaa"
                          />
                          <rect
                            x="63.5"
                            y={39}
                            width={4}
                            height={6}
                            rx={2}
                            ry={2}
                            fill="#3acaaa"
                          />
                          <rect
                            x="69.5"
                            y={39}
                            width={4}
                            height={6}
                            rx={2}
                            ry={2}
                            fill="#3acaaa"
                          />
                          <rect
                            x="75.5"
                            y={39}
                            width={4}
                            height={6}
                            rx={2}
                            ry={2}
                            fill="#3acaaa"
                          />
                          <ellipse
                            cx="45.51"
                            cy={44}
                            rx="15.18"
                            ry={15}
                            fill="#fff"
                            stroke="#139578"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                          />
                          <ellipse
                            cx="45.51"
                            cy={44}
                            rx="11.13"
                            ry={11}
                            fill="#3acaaa"
                          />
                          <path
                            d="M46,50.92s5.5-2.77,5.5-6.92V39.16L46,37.08l-5.5,2.08V44C40.5,48.15,46,50.92,46,50.92Z"
                            fill="#139578"
                          />
                          <polyline
                            points="48.04 42 44.56 46 42.98 44.18"
                            fill="none"
                            stroke="#fff"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                          />
                        </svg>
                      </div>
                    </div>
                  </div>
                  <div className="h6">Secured Payments</div>
                </div>
              </div>
              {/* .card */}
            </div>
            {/* .col */}
            <div className="col-sm-4 col-6">
              <div className="card card-full card-shadow">
                <div className="card-inner">
                  <div className="card-img mb-3">
                    <div className="preview-icon-box card">
                      <div className="preview-icon-wrap">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 90 90"
                          style={{ width: 60 }}
                        >
                          <rect
                            x={5}
                            y={9}
                            width={70}
                            height="51.71"
                            rx={7}
                            ry={7}
                            fill="#3acaaa"
                            stroke="#139578"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                          />
                          <path
                            d="M15,63.7V25.91a7,7,0,0,1,7-7H78a7,7,0,0,1,7,7V63.7a7,7,0,0,1-7,7H31L15,81Z"
                            fill="#fff"
                            stroke="#139578"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                          />
                          <path
                            d="M63,51.72v4.39a2.94,2.94,0,0,1-3,2.94h-.28A29.49,29.49,0,0,1,47,54.54,29.26,29.26,0,0,1,33.58,33.07a2.93,2.93,0,0,1,2.68-3.17l.27,0H41a3,3,0,0,1,3,3.27,3.75,3.75,0,0,0,.63,2.65,2.9,2.9,0,0,1-.27,3.8l-1.88,1.86a23.51,23.51,0,0,0,8.87,8.78l1.88-1.86a3,3,0,0,1,3.15-.65,19.64,19.64,0,0,0,4.13,1A2.93,2.93,0,0,1,63,51.72Z"
                            fill="#3acaaa"
                            stroke="#139578"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                          />
                          <text
                            transform="translate(50.23 41.17) scale(1.01 1)"
                            fontSize="14.07"
                            fill="#9cabff"
                            fontFamily="Nunito-Bold, Nunito"
                            fontWeight={700}
                            letterSpacing="-0.05em"
                          >
                            24
                          </text>
                        </svg>
                      </div>
                    </div>
                  </div>
                  <div className="h6">24/7 Available Support</div>
                </div>
              </div>
              {/* .card */}
            </div>
            {/* .col */}
            <div className="col-sm-4 col-6">
              <div className="card card-full card-shadow">
                <div className="card-inner">
                  <div className="card-img mb-3">
                    <div className="preview-icon-box card">
                      <div className="preview-icon-wrap">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 90 90"
                          style={{ width: 60 }}
                        >
                          <rect
                            x={3}
                            y={10}
                            width={70}
                            height={50}
                            rx={7}
                            ry={7}
                            fill="#3acaaa"
                            stroke="#139578"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                          />
                          <rect
                            x={13}
                            y={24}
                            width={70}
                            height={50}
                            rx={7}
                            ry={7}
                            fill="#fff"
                            stroke="#139578"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                          />
                          <line
                            x1={20}
                            y1={33}
                            x2={31}
                            y2={33}
                            fill="none"
                            stroke="#9cabff"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                          />
                          <line
                            x1={75}
                            y1={33}
                            x2={77}
                            y2={33}
                            fill="none"
                            stroke="#9cabff"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                          />
                          <line
                            x1={66}
                            y1={33}
                            x2={67}
                            y2={33}
                            fill="none"
                            stroke="#9cabff"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                          />
                          <line
                            x1={70}
                            y1={33}
                            x2={72}
                            y2={33}
                            fill="none"
                            stroke="#9cabff"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                          />
                          <rect
                            x={19}
                            y={40}
                            width={56}
                            height={7}
                            rx={2}
                            ry={2}
                            fill="#eff1ff"
                          />
                          <rect
                            x={20}
                            y={51}
                            width={24}
                            height={8}
                            rx={2}
                            ry={2}
                            fill="#eff1ff"
                          />
                          <rect
                            x={48}
                            y={51}
                            width={7}
                            height={8}
                            rx={2}
                            ry={2}
                            fill="#eff1ff"
                          />
                          <ellipse
                            cx={69}
                            cy="61.98"
                            rx={18}
                            ry="18.02"
                            fill="#fff"
                            stroke="#139578"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                          />
                          <circle cx={69} cy={62} r={7} fill="#3acaaa" />
                          <polyline
                            points="77 56 77 60 73 60"
                            fill="none"
                            stroke="#139578"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                          />
                          <path
                            d="M62.26,59.17a6.81,6.81,0,0,1,11.25-2.55L77,59.92"
                            fill="none"
                            stroke="#139578"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                          />
                          <polyline
                            points="61 67 61 63 65 63"
                            fill="none"
                            stroke="#139578"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                          />
                          <path
                            d="M61.43,64l3.51,3.31A6.83,6.83,0,0,0,76.2,64.79"
                            fill="none"
                            stroke="#139578"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                          />
                        </svg>
                      </div>
                    </div>
                  </div>
                  <div className="h6">Re-investment Options</div>
                </div>
              </div>
              {/* .card */}
            </div>
            {/* .col */}
            <div className="col-sm-4 col-6">
              <div className="card card-full card-shadow">
                <div className="card-inner">
                  <div className="card-img mb-3">
                    <div className="preview-icon-box card">
                      <div className="preview-icon-wrap">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 90 90"
                          style={{ width: 60 }}
                        >
                          <rect
                            x={5}
                            y={7}
                            width={60}
                            height={56}
                            rx={7}
                            ry={7}
                            fill="#3acaaa"
                            stroke="#139578"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                          />
                          <rect
                            x={25}
                            y={27}
                            width={60}
                            height={56}
                            rx={7}
                            ry={7}
                            fill="#3acaaa"
                            stroke="#139578"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                          />
                          <rect
                            x={15}
                            y={17}
                            width={60}
                            height={56}
                            rx={7}
                            ry={7}
                            fill="#fff"
                            stroke="#139578"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                          />
                          <line
                            x1={15}
                            y1={29}
                            x2={75}
                            y2={29}
                            fill="none"
                            stroke="#139578"
                            strokeMiterlimit={10}
                            strokeWidth={2}
                          />
                          <circle cx={53} cy={23} r={2} fill="#3acaaa" />
                          <circle cx={60} cy={23} r={2} fill="#3acaaa" />
                          <circle cx={67} cy={23} r={2} fill="#3acaaa" />
                          <rect
                            x={22}
                            y={39}
                            width={20}
                            height={20}
                            rx={2}
                            ry={2}
                            fill="none"
                            stroke="#139578"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                          />
                          <circle
                            cx={32}
                            cy="45.81"
                            r={2}
                            fill="none"
                            stroke="#139578"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                          />
                          <path
                            d="M29,54.31a3,3,0,0,1,6,0"
                            fill="none"
                            stroke="#139578"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                          />
                          <line
                            x1={49}
                            y1={40}
                            x2={69}
                            y2={40}
                            fill="none"
                            stroke="#139578"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                          />
                          <line
                            x1={49}
                            y1={51}
                            x2={69}
                            y2={51}
                            fill="none"
                            stroke="#3acaaa"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                          />
                          <line
                            x1={49}
                            y1={57}
                            x2={59}
                            y2={57}
                            fill="none"
                            stroke="#3acaaa"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                          />
                          <line
                            x1={64}
                            y1={57}
                            x2={66}
                            y2={57}
                            fill="none"
                            stroke="#3acaaa"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                          />
                          <line
                            x1={49}
                            y1={46}
                            x2={59}
                            y2={46}
                            fill="none"
                            stroke="#3acaaa"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                          />
                          <line
                            x1={64}
                            y1={46}
                            x2={66}
                            y2={46}
                            fill="none"
                            stroke="#3acaaa"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                          />
                        </svg>
                      </div>
                    </div>
                  </div>
                  <div className="h6">Multi Referral Tool</div>
                </div>
              </div>
              {/* .card */}
            </div>
            {/* .col */}
          </div>
        </div>
        {/* .col */}
        <div className="col-lg-5">
          <div className="text-block">
            <h2 className="title fw-medium">
              Earn Money By <span className="text-primary">Inviting</span> Your{" "}
              <span className="text-pink">Family &amp; Friends</span>
            </h2>
            <p className="text-justify">
              Faxton Limited offers 5% referral bonus for referral's first
              deposit.
              <br />
              <br /> Withdrawal - Every withdrawal will be processed within 24
              hours. Emergency Withdraw - We know how much money can be
              important! Need Emergency Withdraw? You can withdraw your
              investment and close your account anytime.
            </p>
            {/*<ul class="list list-lg list-primary list-checked-circle outlined">
                      <li>Full native </li>
                      <li>Popular and widely used framework</li>
                      <li>Amazing feature for enhanced performance.</li>
                  </ul>*/}
          </div>
        </div>
      </div>
      {/* .row */}
    </div>
    {/* .container */}
  </section>
  {/*==============================Testimonial Area ==============================*/}
  <section
    className="space background-image"
    style={{
      backgroundImage:
        'url("https://www.faxtonlimited.com/front/assets/img/bg/team_bg_1.jpg")'
    }}
  >
    <div className="container">
      <div className="row">
        <div className="col-xl-5">
          <div className="title-area testimonital-title">
            <span className="sub-title">Testimonial</span>
            <h2 className="sec-title">What Our Client Says?</h2>
            <p className="mt-n2 mb-35">Clients Testimonials.</p>
            <div className="testi-wrapper">
              <div className="testi-text">
                <p>4.8</p>
              </div>
              <div className="testi-star">
                <a href="#">
                  <i className="fad fa-star" />
                </a>{" "}
                <a href="#">
                  <i className="fad fa-star" />
                </a>{" "}
                <a href="#">
                  <i className="fad fa-star" />
                </a>{" "}
                <a href="#">
                  <i className="fad fa-star" />
                </a>{" "}
                <a href="#">
                  <i className="fad fa-star" />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-7">
          <div className="testi-box-slide">
            <div
              className="as-carousel slick-initialized slick-slider"
              id="testislidev1"
              data-slide-show={1}
              data-fade="true"
            >
              <div className="slick-list draggable">
                <div
                  className="slick-track"
                  style={{ opacity: 1, width: 5616 }}
                >
                  <div
                    className="slick-slide"
                    style={{
                      width: 936,
                      position: "relative",
                      left: 0,
                      top: 0,
                      zIndex: 998,
                      opacity: 0,
                      transition: "opacity 1000ms ease 0s"
                    }}
                    tabIndex={-1}
                    data-slick-index={0}
                    aria-hidden="true"
                  >
                    <div className="testi-box">
                      <div className="testi-content style1">
                        <div className="testi-box-wrapper">
                          <div className="star-icon">
                            <a href="#" tabIndex={-1}>
                              <i className="fad fa-star" />
                            </a>{" "}
                            <a href="#" tabIndex={-1}>
                              <i className="fad fa-star" />
                            </a>{" "}
                            <a href="#" tabIndex={-1}>
                              <i className="fad fa-star" />
                            </a>{" "}
                            <a href="#" tabIndex={-1}>
                              <i className="fad fa-star" />
                            </a>{" "}
                            <a href="#" tabIndex={-1}>
                              <i className="fad fa-star" />
                            </a>
                          </div>
                          <div className="testi-quote">
                            <img src="https://www.faxtonlimited.com/front/assets/img/testimonial/quote.png" />
                          </div>
                        </div>
                        <p className="testi-box_text">
                          ““Thanks to Faxton Limited, We live this wonderful
                          life today. Faxton Limited has made us believe in
                          ourselves and every opportunity that comes our way.
                          Now we know we truly deserve every reward We
                          receive.””{" "}
                        </p>
                      </div>
                    </div>
                    <div className="testi-box_profile style1">
                      <div className="testi-box_avater">
                        <img
                          src="https://www.faxtonlimited.com/assets/images/1667727490h3.jpeg"
                          alt="testimonial"
                        />
                      </div>
                      <div className="media-body">
                        <h3 className="testi-box_name h6">
                          Mr &amp; Mrs Habib
                        </h3>{" "}
                        <span className="testi-box_desig">Investor</span>
                      </div>
                    </div>
                  </div>
                  <div
                    className="slick-slide"
                    style={{
                      width: 936,
                      position: "relative",
                      left: "-936px",
                      top: 0,
                      zIndex: 998,
                      opacity: 0,
                      transition: "opacity 1000ms ease 0s"
                    }}
                    tabIndex={-1}
                    data-slick-index={1}
                    aria-hidden="true"
                  >
                    <div className="testi-box">
                      <div className="testi-content style1">
                        <div className="testi-box-wrapper">
                          <div className="star-icon">
                            <a href="#" tabIndex={-1}>
                              <i className="fad fa-star" />
                            </a>{" "}
                            <a href="#" tabIndex={-1}>
                              <i className="fad fa-star" />
                            </a>{" "}
                            <a href="#" tabIndex={-1}>
                              <i className="fad fa-star" />
                            </a>{" "}
                            <a href="#" tabIndex={-1}>
                              <i className="fad fa-star" />
                            </a>{" "}
                            <a href="#" tabIndex={-1}>
                              <i className="fad fa-star" />
                            </a>
                          </div>
                          <div className="testi-quote">
                            <img src="https://www.faxtonlimited.com/front/assets/img/testimonial/quote.png" />
                          </div>
                        </div>
                        <p className="testi-box_text">
                          ““A huge thank you to all the teachers and creators of
                          financial freedom through this great vehicle called
                          Faxton.””{" "}
                        </p>
                      </div>
                    </div>
                    <div className="testi-box_profile style1">
                      <div className="testi-box_avater">
                        <img
                          src="https://www.faxtonlimited.com/assets/images/1667727602h3.jpeg"
                          alt="testimonial"
                        />
                      </div>
                      <div className="media-body">
                        <h3 className="testi-box_name h6">Sarah Clinton</h3>{" "}
                        <span className="testi-box_desig">Investor</span>
                      </div>
                    </div>
                  </div>
                  <div
                    className="slick-slide"
                    style={{
                      width: 936,
                      position: "relative",
                      left: "-1872px",
                      top: 0,
                      zIndex: 998,
                      opacity: 0,
                      transition: "opacity 1000ms ease 0s"
                    }}
                    tabIndex={-1}
                    data-slick-index={2}
                    aria-hidden="true"
                  >
                    <div className="testi-box">
                      <div className="testi-content style1">
                        <div className="testi-box-wrapper">
                          <div className="star-icon">
                            <a href="#" tabIndex={-1}>
                              <i className="fad fa-star" />
                            </a>{" "}
                            <a href="#" tabIndex={-1}>
                              <i className="fad fa-star" />
                            </a>{" "}
                            <a href="#" tabIndex={-1}>
                              <i className="fad fa-star" />
                            </a>{" "}
                            <a href="#" tabIndex={-1}>
                              <i className="fad fa-star" />
                            </a>{" "}
                            <a href="#" tabIndex={-1}>
                              <i className="fad fa-star" />
                            </a>
                          </div>
                          <div className="testi-quote">
                            <img src="https://www.faxtonlimited.com/front/assets/img/testimonial/quote.png" />
                          </div>
                        </div>
                        <p className="testi-box_text">
                          ““Every mind-blowing fact that you have heard about
                          Faxton Limited is 100% true! It is most definitely
                          among the best of the best companies worldwide. What
                          are you waiting for? This is your turn to join us and
                          never, ever look back.””{" "}
                        </p>
                      </div>
                    </div>
                    <div className="testi-box_profile style1">
                      <div className="testi-box_avater">
                        <img
                          src="https://www.faxtonlimited.com/assets/images/1667727659h3.jpeg"
                          alt="testimonial"
                        />
                      </div>
                      <div className="media-body">
                        <h3 className="testi-box_name h6">Mason Owen</h3>{" "}
                        <span className="testi-box_desig">Student</span>
                      </div>
                    </div>
                  </div>
                  <div
                    className="slick-slide"
                    style={{
                      width: 936,
                      position: "relative",
                      left: "-2808px",
                      top: 0,
                      zIndex: 998,
                      opacity: 0,
                      transition: "opacity 1000ms ease 0s"
                    }}
                    tabIndex={-1}
                    data-slick-index={3}
                    aria-hidden="true"
                  >
                    <div className="testi-box">
                      <div className="testi-content style1">
                        <div className="testi-box-wrapper">
                          <div className="star-icon">
                            <a href="#" tabIndex={-1}>
                              <i className="fad fa-star" />
                            </a>{" "}
                            <a href="#" tabIndex={-1}>
                              <i className="fad fa-star" />
                            </a>{" "}
                            <a href="#" tabIndex={-1}>
                              <i className="fad fa-star" />
                            </a>{" "}
                            <a href="#" tabIndex={-1}>
                              <i className="fad fa-star" />
                            </a>{" "}
                            <a href="#" tabIndex={-1}>
                              <i className="fad fa-star" />
                            </a>
                          </div>
                          <div className="testi-quote">
                            <img src="https://www.faxtonlimited.com/front/assets/img/testimonial/quote.png" />
                          </div>
                        </div>
                        <p className="testi-box_text">
                          ““Today I can wholeheartedly tell you that Faxton
                          Limited is my blessing and it will always be. The best
                          part is, it can be your blessing too. Just take the
                          leap.””{" "}
                        </p>
                      </div>
                    </div>
                    <div className="testi-box_profile style1">
                      <div className="testi-box_avater">
                        <img
                          src="https://www.faxtonlimited.com/assets/images/1667727715h3.jpeg"
                          alt="testimonial"
                        />
                      </div>
                      <div className="media-body">
                        <h3 className="testi-box_name h6">Elizabeth Walker</h3>{" "}
                        <span className="testi-box_desig">School Teacher</span>
                      </div>
                    </div>
                  </div>
                  <div
                    className="slick-slide"
                    style={{
                      width: 936,
                      position: "relative",
                      left: "-3744px",
                      top: 0,
                      zIndex: 998,
                      opacity: 0,
                      transition: "opacity 1000ms ease 0s"
                    }}
                    tabIndex={-1}
                    data-slick-index={4}
                    aria-hidden="true"
                  >
                    <div className="testi-box">
                      <div className="testi-content style1">
                        <div className="testi-box-wrapper">
                          <div className="star-icon">
                            <a href="#" tabIndex={-1}>
                              <i className="fad fa-star" />
                            </a>{" "}
                            <a href="#" tabIndex={-1}>
                              <i className="fad fa-star" />
                            </a>{" "}
                            <a href="#" tabIndex={-1}>
                              <i className="fad fa-star" />
                            </a>{" "}
                            <a href="#" tabIndex={-1}>
                              <i className="fad fa-star" />
                            </a>{" "}
                            <a href="#" tabIndex={-1}>
                              <i className="fad fa-star" />
                            </a>
                          </div>
                          <div className="testi-quote">
                            <img src="https://www.faxtonlimited.com/front/assets/img/testimonial/quote.png" />
                          </div>
                        </div>
                        <p className="testi-box_text">
                          ““What better than living the dream and being a part
                          of this great Faxton Family...don't hesitate, don't be
                          afraid!!! Take THAT step today and you will count your
                          blessings tomorrow.””{" "}
                        </p>
                      </div>
                    </div>
                    <div className="testi-box_profile style1">
                      <div className="testi-box_avater">
                        <img
                          src="https://www.faxtonlimited.com/assets/images/1667727769h3.jpeg"
                          alt="testimonial"
                        />
                      </div>
                      <div className="media-body">
                        <h3 className="testi-box_name h6">Jerome Jenkins</h3>{" "}
                        <span className="testi-box_desig">Investor</span>
                      </div>
                    </div>
                  </div>
                  <div
                    className="slick-slide slick-current slick-active"
                    style={{
                      width: 936,
                      position: "relative",
                      left: "-4680px",
                      top: 0,
                      zIndex: 999,
                      opacity: 1
                    }}
                    tabIndex={0}
                    data-slick-index={5}
                    aria-hidden="false"
                  >
                    <div className="testi-box">
                      <div className="testi-content style1">
                        <div className="testi-box-wrapper">
                          <div className="star-icon">
                            <a href="#" tabIndex={0}>
                              <i className="fad fa-star" />
                            </a>{" "}
                            <a href="#" tabIndex={0}>
                              <i className="fad fa-star" />
                            </a>{" "}
                            <a href="#" tabIndex={0}>
                              <i className="fad fa-star" />
                            </a>{" "}
                            <a href="#" tabIndex={0}>
                              <i className="fad fa-star" />
                            </a>{" "}
                            <a href="#" tabIndex={0}>
                              <i className="fad fa-star" />
                            </a>
                          </div>
                          <div className="testi-quote">
                            <img src="https://www.faxtonlimited.com/front/assets/img/testimonial/quote.png" />
                          </div>
                        </div>
                        <p className="testi-box_text">
                          ““Have you ever experienced the magical feeling of a
                          dream unfolding into a reality right in front of your
                          eyes? Faxton is my reality. We dream together, we
                          celebrate together. Then we think bigger and achieve
                          better. Do not wait, give your life the Faxton
                          makeover.””{" "}
                        </p>
                      </div>
                    </div>
                    <div className="testi-box_profile style1">
                      <div className="testi-box_avater">
                        <img
                          src="https://www.faxtonlimited.com/assets/images/1667727913h3.jpeg"
                          alt="testimonial"
                        />
                      </div>
                      <div className="media-body">
                        <h3 className="testi-box_name h6">Crystal Roberts</h3>{" "}
                        <span className="testi-box_desig">Investor</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="as-slick-custom style1 testislidev1-navs text-center">
              <button
                className="slick-arrow slick-prev"
                data-slick-prev="#testislidev1"
              >
                <i className="fal fa-long-arrow-left" />
              </button>
              <div className="as-custom-dots" data-asnavfor="#testislidev1">
                <button className="dot-btn" data-slide-go-to={0}>
                  1
                </button>
                <button className="dot-btn" data-slide-go-to={1}>
                  2
                </button>
                <button className="dot-btn" data-slide-go-to={2}>
                  3
                </button>
                <button className="dot-btn" data-slide-go-to={3}>
                  4
                </button>
                <button className="dot-btn" data-slide-go-to={4}>
                  5
                </button>
                <button className="dot-btn active" data-slide-go-to={5}>
                  6
                </button>
                {/* <button class="dot-btn">4</button> */}
              </div>
              <button
                className="slick-arrow slick-next"
                data-slick-next="#testislidev1"
              >
                <i className="fal fa-long-arrow-right" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  {/*==============================Blog Area ==============================*/}
  <section
    className="count-up-sec bg-light-color text-white"
    style={{ paddingTop: 20 }}
  >
    <div className="count-up-sec-overlay" />
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-lg-8">
          <div className="section-title text-center">
            <h2 className="title-effect">Profit Calculator</h2>
            <p>
              You must know the calculation before investing in any plan, so you
              never make mistakes. Check the calculation and you will get as our
              calculator says.
            </p>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-1" />
        <div className="col-md-10">
          <div
            className="single-table"
            style={{
              boxShadow: "0px 0px 5px rgb(0 0 0 / 20%)",
              padding: 25,
              backgroundColor: "#ffffff"
            }}
          >
            <div>
              <h5 id="percentage">0.9% Profit</h5>
            </div>
            <form action="#" method="post" className="">
              <div className="row">
                <div className="col-md-6">
                  <label style={{ textAlign: "left", color: "black" }}>
                    Plan:
                  </label>
                  <select
                    type="text"
                    style={{ border: "1px solid #139578" }}
                    className="form-control"
                    id="plan"
                    name="plan"
                    data-ddg-inputtype="unknown"
                  >
                    <option value="0.9">Savers</option>
                    <option value="1.17">Management</option>
                    <option value="1.33">Premium</option>
                    <option value="1.67">Platinum</option>
                    <option value={2}>Cooperate</option>
                  </select>
                </div>
                <div className="col-md-6">
                  <label style={{ textAlign: "left", color: "black" }}>
                    Amount:
                  </label>
                  <input
                    style={{ fontSize: "large", border: "1px solid #139578" }}
                    type="number"
                    min={100}
                    defaultValue=""
                    minLength={4}
                    className="form-control"
                    id="amount"
                    name="amount"
                    data-ddg-inputtype="unknown"
                  />
                </div>
                <div className="col-md-12" id="totalcalc">
                  <label style={{ textAlign: "left", color: "black" }}>
                    Revenue:
                  </label>
                  <input
                    style={{ fontSize: "large" }}
                    type="text"
                    className="form-control input"
                    id="ttcal"
                    defaultValue=""
                    readOnly=""
                    data-ddg-inputtype="unknown"
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section
    className="space bg-top-center background-image"
    id="blog-sec"
    style={{
      backgroundImage:
        'url("https://www.faxtonlimited.com/front/assets/img/bg/blog_bg.png")'
    }}
  >
    <div className="container">
      <div className="row medium-padding50 align-center">
        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <header className="crumina-module crumina-heading heading--h2 heading--with-decoration">
            <h2 className="heading-title weight-normal">
              News On The
              <span className="weight-bold"> Go</span>
            </h2>
            <div className="heading-text">
              Get all the news on cryptocurrencies
            </div>
          </header>
          <div
            className="feedgrabbr_widget"
            id="fgid_c9a1f49f053c86643356ce175"
          >
            <style
              dangerouslySetInnerHTML={{
                __html:
                  "\n    /* Common */\n    #fg-widget-c9a1f49f053c86643356ce175 {\n        width: 100%;\n        border-color: #202020;\n    }\n    #fg-widget-c9a1f49f053c86643356ce175 .uw-sc-snippet {\n                    font-size: 10.0pt;\n                color: #202020;\n                    \n    }\n    #fg-widget-c9a1f49f053c86643356ce175 .uw-sc-date {\n                    font-size: 10.0pt;\n                color: #d0d0d0;\n    }\n    #fg-widget-c9a1f49f053c86643356ce175 .uw-source {\n                    font-size: 10.0pt;\n                color: #d0d0d0;\n    }\n\n    /* Widget specific */\n    \n    \n    \n            #fg-widget-c9a1f49f053c86643356ce175 .uw-ib-footpanel {\n                            font-size: 10.0pt;\n                        color: #ffffff;\n                            \n        }\n    \n    /* Widget groups */\n            #fg-widget-c9a1f49f053c86643356ce175:not(.uw-transparent) .uw-w-header, #fg-widget-c9a1f49f053c86643356ce175:not(.uw-transparent) .uw-w-branding {\n            background-color: #e8e8e8;\n        }\n        #fg-widget-c9a1f49f053c86643356ce175 .uw-w-header  span {\n                            font-size: 10.0pt;\n                    }\n        #fg-widget-c9a1f49f053c86643356ce175 .uw-w-header span {\n            color: #202020;\n        }\n                    #fg-widget-c9a1f49f053c86643356ce175 .uw-w-header .uw-logo {\n                height: 24px;\n            }\n            \n            #fg-widget-c9a1f49f053c86643356ce175 {\n            height: 500px;\n        }\n    "
              }}
            />
            <div
              id="fg-widget-c9a1f49f053c86643356ce175"
              className=" uw-rssmode uw-showbranding uw-showdate uw-ib-border uw-ib-showtext uw-showheaderfollow uw-imageboard4"
            >
              <div className="uw-w-header">
                <span>The header</span>
              </div>
              <div className="uw-ib-cardcont">
                <div className="uw-ib-cardcont2">
                  <div className="uw-ib-card uw-ib-1" data-id={296256175}>
                    <a
                      className="uw-imgcont"
                      href="https://www.coindesk.com/markets/2023/08/12/shiba-inu-open-interest-tops-100m-raises-red-flag-for-bitcoin/?utm_medium=referral&utm_source=rss&utm_campaign=headlines"
                      target="_blank"
                      rel="nofollow"
                      style={{
                        backgroundImage:
                          'url("https://www.coindesk.com/resizer/nDcOFM-V6JLxygI5qjvMQm2qW7A=/800x600/cloudfront-us-east-1.images.arcpublishing.com/coindesk/JRQ4W5GAEZGXDHPPJNQPJLSRWQ.jpg")'
                      }}
                    />
                    <div className="uw-ib-footpanel">
                      <a
                        className="uw-text"
                        href="https://www.coindesk.com/markets/2023/08/12/shiba-inu-open-interest-tops-100m-raises-red-flag-for-bitcoin/?utm_medium=referral&utm_source=rss&utm_campaign=headlines"
                        target="_blank"
                        rel="nofollow"
                      >
                        Shiba Inu Open Interest Tops $100M, Raises Red Flag For
                        Bitcoin
                      </a>
                      <div className="uw-rss">
                        <span className="uw-source">coindesk.com</span>
                        <span className="uw-source-divider">|</span>
                        <span className="uw-sc-date">10 hours ago</span>
                      </div>
                    </div>
                  </div>
                  <div className="uw-ib-card uw-ib-2" data-id={296256176}>
                    <a
                      className="uw-imgcont"
                      href="https://www.coindesk.com/policy/2023/08/12/us-senator-lummis-crypto-lobbyists-urge-court-to-dismiss-secs-coinbase-lawsuit/?utm_medium=referral&utm_source=rss&utm_campaign=headlines"
                      target="_blank"
                      rel="nofollow"
                      style={{
                        backgroundImage:
                          'url("https://www.coindesk.com/resizer/iucmBrri5tK7y9S1xz1Gf2nb5kc=/800x600/cloudfront-us-east-1.images.arcpublishing.com/coindesk/K2TLJAL27RAPZHI62YJXIXGEME.jpg")'
                      }}
                    />
                    <div className="uw-ib-footpanel">
                      <a
                        className="uw-text"
                        href="https://www.coindesk.com/policy/2023/08/12/us-senator-lummis-crypto-lobbyists-urge-court-to-dismiss-secs-coinbase-lawsuit/?utm_medium=referral&utm_source=rss&utm_campaign=headlines"
                        target="_blank"
                        rel="nofollow"
                      >
                        U.S. Senator Lummis, Crypto Lobbyists Urge Court to
                        Dismiss SEC's Coinbase Lawsuit
                      </a>
                      <div className="uw-rss">
                        <span className="uw-source">coindesk.com</span>
                        <span className="uw-source-divider">|</span>
                        <span className="uw-sc-date">12 hours ago</span>
                      </div>
                    </div>
                  </div>
                  <div className="uw-ib-card uw-ib-3" data-id={296247121}>
                    <a
                      className="uw-imgcont"
                      href="https://www.coindesk.com/policy/2023/08/11/sam-bankman-fried-jailed-ahead-of-trial/?utm_medium=referral&utm_source=rss&utm_campaign=headlines"
                      target="_blank"
                      rel="nofollow"
                      style={{
                        backgroundImage:
                          'url("https://www.coindesk.com/resizer/1DrauYwzCMKkcC_OpbAWPdrPjxY=/800x600/cloudfront-us-east-1.images.arcpublishing.com/coindesk/L3KTDJMSKVA7PJYRJCZXLAACZM.jpg")'
                      }}
                    />
                    <div className="uw-ib-footpanel">
                      <a
                        className="uw-text"
                        href="https://www.coindesk.com/policy/2023/08/11/sam-bankman-fried-jailed-ahead-of-trial/?utm_medium=referral&utm_source=rss&utm_campaign=headlines"
                        target="_blank"
                        rel="nofollow"
                      >
                        Sam Bankman-Fried Jailed Ahead of Trial
                      </a>
                      <div className="uw-rss">
                        <span className="uw-source">coindesk.com</span>
                        <span className="uw-source-divider">|</span>
                        <span className="uw-sc-date">15 hours ago</span>
                      </div>
                    </div>
                  </div>
                  <div className="uw-ib-card uw-ib-4" data-id={296235168}>
                    <a
                      className="uw-imgcont"
                      href="https://www.coindesk.com/policy/2023/08/11/ark-21shares-bitcoin-etf-application-decision-pushed-by-sec/?utm_medium=referral&utm_source=rss&utm_campaign=headlines"
                      target="_blank"
                      rel="nofollow"
                      style={{
                        backgroundImage:
                          'url("https://www.coindesk.com/resizer/tJRt8-udvpAIdfE-w1ak7wAlTI8=/800x600/cloudfront-us-east-1.images.arcpublishing.com/coindesk/SRAITNHMLBGTZIS5JCSTVNXM2Y.jpg")'
                      }}
                    />
                    <div className="uw-ib-footpanel">
                      <a
                        className="uw-text"
                        href="https://www.coindesk.com/policy/2023/08/11/ark-21shares-bitcoin-etf-application-decision-pushed-by-sec/?utm_medium=referral&utm_source=rss&utm_campaign=headlines"
                        target="_blank"
                        rel="nofollow"
                      >
                        Cathie Wood's Ark 21Shares Bitcoin ETF Application
                        Decision Pushed Out by SEC
                      </a>
                      <div className="uw-rss">
                        <span className="uw-source">coindesk.com</span>
                        <span className="uw-source-divider">|</span>
                        <span className="uw-sc-date">19 hours ago</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <a
                href="https://feedgrabbr.com"
                target="_blank"
                className="uw-w-branding"
                title="FeedGrabbr"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  {/* END news */}
  {/*==============================Footer Area==============================*/}
  <Footer/>
  {/*********************************Code End Here ******************************** */}
  {/* Scroll To Top */}
  <div className="scroll-top">
    <svg
      className="progress-circle svg-content"
      width="100%"
      height="100%"
      viewBox="-1 -1 102 102"
    >
      <path
        d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98"
        style={{
          transition: "stroke-dashoffset 10ms linear 0s",
          strokeDasharray: "307.919, 307.919",
          strokeDashoffset: "307.919"
        }}
      />
    </svg>
  </div>
  {/*============================== All Js File============================== */}
  {/* Jquery */}
  {/*  */}
  {/* Slick Slider */}
  {/* Bootstrap */}
  {/* Magnific Popup */}
  {/* Counter Up */}
  {/* Range Slider */}
  {/* Isotope Filter */}
  {/* Flip Slider */}
  {/* Main Js File */}
  {/* Smartsupp Live Chat script */}
  <div
    id="goog-gt-tt"
    className="VIpgJd-yAWNEb-L7lbkb skiptranslate"
    style={{
      borderRadius: 12,
      margin: "0 0 0 -23px",
      padding: 0,
      fontFamily: '"Google Sans", Arial, sans-serif'
    }}
    data-id=""
  >
    <div id="goog-gt-vt" className="VIpgJd-yAWNEb-hvhgNd">
      <div className=" VIpgJd-yAWNEb-hvhgNd-l4eHX-i3jM8c">
        <img
          src="https://fonts.gstatic.com/s/i/productlogos/translate/v14/24px.svg"
          width={24}
          height={24}
          alt=""
        />
      </div>
      <div className=" VIpgJd-yAWNEb-hvhgNd-k77Iif-i3jM8c">
        <div className="VIpgJd-yAWNEb-hvhgNd-IuizWc" dir="ltr">
          Original text
        </div>
        <div
          id="goog-gt-original-text"
          className="VIpgJd-yAWNEb-nVMfcd-fmcmS VIpgJd-yAWNEb-hvhgNd-axAV1"
        />
      </div>
      <div className="VIpgJd-yAWNEb-hvhgNd-N7Eqid ltr">
        <div className="VIpgJd-yAWNEb-hvhgNd-N7Eqid-B7I4Od ltr" dir="ltr">
          <div className="VIpgJd-yAWNEb-hvhgNd-UTujCb">
            Rate this translation
          </div>
          <div className="VIpgJd-yAWNEb-hvhgNd-eO9mKe">
            Your feedback will be used to help improve Google Translate
          </div>
        </div>
        <div className="VIpgJd-yAWNEb-hvhgNd-xgov5 ltr">
          <button
            id="goog-gt-thumbUpButton"
            type="button"
            className="VIpgJd-yAWNEb-hvhgNd-bgm6sf"
            title="Good translation"
            aria-label="Good translation"
            aria-pressed="false"
          >
            <span id="goog-gt-thumbUpIcon">
              <svg
                width={24}
                height={24}
                viewBox="0 0 24 24"
                focusable="false"
                className="VIpgJd-yAWNEb-hvhgNd-THI6Vb NMm5M"
              >
                <path d="M21 7h-6.31l.95-4.57.03-.32c0-.41-.17-.79-.44-1.06L14.17 0S7.08 6.85 7 7H2v13h16c.83 0 1.54-.5 1.84-1.22l3.02-7.05c.09-.23.14-.47.14-.73V9c0-1.1-.9-2-2-2zM7 18H4V9h3v9zm14-7l-3 7H9V8l4.34-4.34L12 9h9v2z" />
              </svg>
            </span>
            <span id="goog-gt-thumbUpIconFilled">
              <svg
                width={24}
                height={24}
                viewBox="0 0 24 24"
                focusable="false"
                className="VIpgJd-yAWNEb-hvhgNd-THI6Vb NMm5M"
              >
                <path d="M21 7h-6.31l.95-4.57.03-.32c0-.41-.17-.79-.44-1.06L14.17 0S7.08 6.85 7 7v13h11c.83 0 1.54-.5 1.84-1.22l3.02-7.05c.09-.23.14-.47.14-.73V9c0-1.1-.9-2-2-2zM5 7H1v13h4V7z" />
              </svg>
            </span>
          </button>
          <button
            id="goog-gt-thumbDownButton"
            type="button"
            className="VIpgJd-yAWNEb-hvhgNd-bgm6sf"
            title="Poor translation"
            aria-label="Poor translation"
            aria-pressed="false"
          >
            <span id="goog-gt-thumbDownIcon">
              <svg
                width={24}
                height={24}
                viewBox="0 0 24 24"
                focusable="false"
                className="VIpgJd-yAWNEb-hvhgNd-THI6Vb NMm5M"
              >
                <path d="M3 17h6.31l-.95 4.57-.03.32c0 .41.17.79.44 1.06L9.83 24s7.09-6.85 7.17-7h5V4H6c-.83 0-1.54.5-1.84 1.22l-3.02 7.05c-.09.23-.14.47-.14.73v2c0 1.1.9 2 2 2zM17 6h3v9h-3V6zM3 13l3-7h9v10l-4.34 4.34L12 15H3v-2z" />
              </svg>
            </span>
            <span id="goog-gt-thumbDownIconFilled">
              <svg
                width={24}
                height={24}
                viewBox="0 0 24 24"
                focusable="false"
                className="VIpgJd-yAWNEb-hvhgNd-THI6Vb NMm5M"
              >
                <path d="M3 17h6.31l-.95 4.57-.03.32c0 .41.17.79.44 1.06L9.83 24s7.09-6.85 7.17-7V4H6c-.83 0-1.54.5-1.84 1.22l-3.02 7.05c-.09.23-.14.47-.14.73v2c0 1.1.9 2 2 2zm16 0h4V4h-4v13z" />
              </svg>
            </span>
          </button>
        </div>
      </div>
      <div
        id="goog-gt-votingHiddenPane"
        className="VIpgJd-yAWNEb-hvhgNd-aXYTce"
      >
        <form
          id="goog-gt-votingForm"
          action="//translate.googleapis.com/translate_voting?client=te"
          method="post"
          target="votingFrame"
          className="VIpgJd-yAWNEb-hvhgNd-aXYTce"
        >
          <input
            type="text"
            name="sl"
            id="goog-gt-votingInputSrcLang"
            data-ddg-inputtype="unknown"
          />
          <input
            type="text"
            name="tl"
            id="goog-gt-votingInputTrgLang"
            data-ddg-inputtype="unknown"
          />
          <input
            type="text"
            name="query"
            id="goog-gt-votingInputSrcText"
            data-ddg-inputtype="unknown"
          />
          <input
            type="text"
            name="gtrans"
            id="goog-gt-votingInputTrgText"
            data-ddg-inputtype="unknown"
          />
          <input
            type="text"
            name="vote"
            id="goog-gt-votingInputVote"
            data-ddg-inputtype="unknown"
          />
        </form>
        <iframe name="votingFrame" frameBorder={0} />
      </div>
    </div>
  </div>
  <div className="VIpgJd-ZVi9od-aZ2wEe-wOHMyf">
    <div className="VIpgJd-ZVi9od-aZ2wEe-OiiCO">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="VIpgJd-ZVi9od-aZ2wEe"
        width="96px"
        height="96px"
        viewBox="0 0 66 66"
      >
        <circle
          className="VIpgJd-ZVi9od-aZ2wEe-Jt5cK"
          fill="none"
          strokeWidth={6}
          strokeLinecap="round"
          cx={33}
          cy={33}
          r={30}
        />
      </svg>
    </div>
  </div>
  <iframe
    id="chat-application-iframe"
    title="Smartsupp"
    aria-hidden="true"
    style={{
      display: "block",
      position: "fixed",
      top: 0,
      left: 0,
      width: 1,
      height: 1,
      opacity: 0,
      border: "none",
      zIndex: -1,
      pointerEvents: "none"
    }}
  />
  <div id="smartsupp-widget-container">
    {" "}
    <div
      data-testid="widgetButtonFrame"
      style={{
        borderRadius: 9999,
        boxShadow:
          "rgba(0, 0, 0, 0.06) 0px 1px 6px 0px, rgba(0, 0, 0, 0.12) 0px 2px 32px 0px",
        height: 56,
        position: "fixed",
        bottom: 24,
        left: "initial",
        right: 12,
        zIndex: 10000000,
        width: 111
      }}
    >
      <iframe
        id="widgetButtonFrame"
        title="Smartsupp widget button"
        style={{
          position: "absolute",
          width: "100%",
          height: "100%",
          border: "none",
          display: "block",
          textAlign: "left",
          margin: 0,
          padding: 0,
          top: 0,
          left: 0,
          opacity: 1
        }}
        allowFullScreen=""
        scrolling="no"
      />
    </div>
  </div>
</>

  )
}
