import React from 'react'
import Modal from './Modal'
import { ToastContainer } from 'react-toastify';
import { AmountContext } from '../App';

export default function Navbar() {
  const {showModal, setShowModal} = React.useContext(AmountContext);
  const {user} = React.useContext(AmountContext);

  return (
    <>
      <header className="as-header header-layout1">
        <div className="header-top">
          <div className="container-fluid text-lg-start text-center">
            <div className="menu-top">
              <div className="row justify-content-center justify-content-lg-between align-items-center gy-2">
                <div className="col-auto d-none d-xl-block">
                  <div className="header-links">
                    <ul>
                      <li>
                        <i className="fat fa-envelope" />
                        <span>Email :</span>
                        <a href="mailto:support@faxtoninvestments.org">
                          {" "}
                          support@faxtoninvestments.org

                        </a>
                      </li>
                      <li>
                        <i className="fal fa-clock" />
                        <span>Opening :</span>
                        <a href="#"> Mon-Sat: 9am-6pm</a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-auto d-none d-xl-block">
                  <div className="header-right">
                    <div className="header-links">
                      <ul>
                        <li>
                          <i className="fat fa-location-dot" />
                          <a href="#">Office Address : 1201 NEW YORK AVENUE NW
WASHINGTON, DC</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="cc" style={{ display: "none" }}>
          <div className="container text-center">
            <div className="header-links">
              <ul>
                <li>
                  <a href="/login">
                    <i className="fat fa-lock" /> Login
                  </a>
                </li>
                <li>
                  <a href="/register">
                    <i className="fat fa-user-plus" /> Register
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="sticky-wrapper">
          <div className="menu-area">
            <div className="container-fluid">
              <div className="row align-items-center justify-content-between">
                <div className="col-auto">
                  <nav className="main-menu d-none d-xl-inline-block">
                    <ul>
                      <li className="active">
                        <a href="/">Home</a>
                      </li>
                      <li className="">
                        <a href="/about">About</a>
                      </li>
                      <li className="">
                        <a href="/services">
                          Services
                        </a>
                      </li>
                      <li className="">
                        <a href="/pricing">Pricing</a>
                      </li>
                      <li className="">
                        <a href="/market">Market</a>
                      </li>
                      <li className="">
                        <a href="/compounding">
                          Compounding
                        </a>
                      </li>
                      <li className="">
                        <a href="/contact">Contact</a>
                      </li>
                      {/* <li className="menu-item-has-children">
                    <a href="#">Account</a>
                    <ul className="sub-menu">
                      <li>
                        <a href="/register">
                          Register
                        </a>
                      </li>
                      <li>
                        <a href="/login">Login</a>
                      </li>
                    </ul>
                  </li> */}
                    </ul>
                  </nav>
                  <button
                    type="button"
                    className="as-menu-toggle d-inline-block d-xl-none"
                  >
                    <i className="far fa-bars" />
                  </button>
                </div>
                <div
                  className="header-logo background-image"
                  style={{ backgroundImage: 'url("")' }}
                >
                  <a href="/" style={{
                    display: 'flex',
                    justifyContent:'center'
                  }}>
                    <img
                      src="https://www.faxtonlimited.com/front/images/logo.png"
                      alt=""
                      style={{ width: 80, height: 80 }}
                    />
                  </a>
                </div>
                <div className="col-auto">
                  <div className="header-social">
                    {
                     user ? (
                        <div className="balance-toggle svelte-1rik8j6" data-test="balance-toggle" style={{
                          marginRight: 20
                        }}>
                          <div className="coin-toggle svelte-a8lkyv">
                            <div className="wrapper svelte-a8lkyv">
                              {" "}
                              <div className="dropdown svelte-165m65k">
                                <button
                                  data-test="coin-toggle"
                                  className="button variant-tab size-sm align-left rounded svelte-1epmied"
                                  type="button"
                                  aria-label="Open Dropdown"
                                  data-active-currency="btc"
                                >
                                  {" "}
                                  <span className="content-or-loader svelte-1uofbko">
                                    <div className="wrap svelte-beb1sp">
                                      <div className="currency svelte-1kyocbx">
                                        {" "}
                                        <span className="content svelte-1kyocbx" style={{}}>
                                          <span
                                            style={{ maxWidth: "12ch" }}
                                            className="weight-semibold line-height-default align-left size-default text-size-default variant-highlighted numeric with-icon-space truncate svelte-1myjzud"
                                          >
                                            0.00 BTC
                                          </span>
                                        </span>{" "}
                                        <span
                                          style={{ maxWidth: "12ch" }}
                                          className="weight-normal line-height-default align-left size-default text-size-default variant-subtle truncate svelte-1myjzud"
                                          title="btc"
                                        >
                                          <svg className="svg-icon svelte-kjfvep">
                                            <use xlinkHref="#icon-currency-btc" />
                                          </svg>
                                        </span>{" "}
                                      </div>
                                    </div>{" "}
                                    <svg
                                      className="svg-icon svelte-kjfvep"
                                      style={{
                                        transform: "rotate(0deg)",
                                        fontSize: "var(--text-size-md)"
                                      }}
                                    >
                                      <use xlinkHref="#icon-arrowdown" />
                                    </svg>
                                  </span>{" "}
                                </button>{" "}
                              </div>
                            </div>
                          </div>{" "}
                          <button
                            data-test="wallet"
                            className="as-btn border-btn shadow-none"
                            style={{
                              borderRadius: "0 var(--border-radius-0-25) var(--border-radius-0-25) 0"
                            }}

                            onClick={() => setShowModal(true)}
                          >
                            {" "}
                            <span className="content-or-loader svelte-1uofbko">
                              <span>Wallet</span>
                            </span>{" "}
                          </button>
                        </div>

                      ) : (
                        <a
                          href="/register"
                          className="as-btn border-btn shadow-none"
                          tabIndex={0}
                        >
                          Join Us
                        </a>
                      )
                    }

                    <div className="header-info">
                      <div className="header-info_icon style1">
                        <i className="fat fa-phone" />
                      </div>
                      <div className="media-body">
                        <span className="header-info_label">Call Anytime 24/7</span>
                        <p className="header-info_link">
                          <a href="tel:+13212855036">+1(321)2855036</a>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>

      {
        showModal && <Modal setShowModal={setShowModal}/>
      }
      <ToastContainer position='top-center'/>
    </>
  )
}
