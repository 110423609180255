import React from 'react'

export default function Footer() {
  return (
    <>
        <div
    className="footer-wrapper space-top footer-layout1 background-image"
    style={{
      backgroundImage:
        'url("https://www.faxtonlimited.com/front/assets/img/bg/footer_bg_1.png")'
    }}
  >
    <div className="container">
      <div className="widget-area">
        <div className="row justify-content-between">
          <div className="col-md-6 col-xxl-3 col-xl-4">
            <div className="widget footer-widget">
              <div className="as-widget-about">
                <div className="about-logo">
                  <a href="/">
                    <img
                      src="https://www.faxtonlimited.com/landing/img/fx.png"
                      alt="Faxton"
                      style={{ width: 150 }}
                    />
                  </a>
                </div>
                <p className="about-text">
                  We specialize in trading and managing accounts and funds for
                  her investors; ensuring that investors get their promised
                  returns on investment. A few years ago the platform specially
                  traded for a few employed members of the sponsor organisations
                  and kept pushing to take it global.
                </p>
                {/* <div className="as-social footer-social">
                  <a href="http://www.facebook.com">
                    <i className="fab fa-facebook-f" />
                  </a>
                  <a href="https://twitter.com/">
                    <i className="fab fa-twitter" />
                  </a>
                  <a href="http://youtube.com">
                    <i className="fab fa-whatsapp" />
                  </a>
                  <a href="http://linkedin.com/">
                    <i className="fab fa-linkedin-in" />
                  </a>
                </div> */}
              </div>
            </div>
          </div>
          <div className="col-md-6 col-xl-auto">
            <div className="widget widget_nav_menu footer-widget">
              <h3 className="widget_title">Useful Links</h3>
              <div className="menu-all-pages-container">
                <ul className="menu">
                  <li>
                    <a href="/">Home</a>
                  </li>
                  <li>
                    <a href="/partnershipplans">
                      Partnership Plans
                    </a>
                  </li>
                  <li>
                    <a href="/affiliate">
                      Affiliate
                    </a>
                  </li>
                  <li>
                    <a href="/termsandconditions">
                      Terms and Conditions
                    </a>
                  </li>
                  <li>
                    <a href="/privacy">
                      Privacy Policy
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-xl-auto">
            <div className="widget footer-widget">
              <h3 className="widget_title">Contact Now</h3>
              <div className="as-widget-contact">
                <div className="info-box">
                  <div className="info-box_icon">
                    <i className="fal fa-location-dot" />
                  </div>
                  <p className="info-box_text">1201 NEW YORK AVENUE NW
WASHINGTON, DC 
</p>
                </div>
                <div className="info-box">
                  <div className="info-box_icon">
                    <i className="fa-regular fa-mobile" />
                  </div>
                  <p className="info-box_text">
                    <a href="tel:+13212855036" className="info-box_link">
                    +1(321)2855036
                    </a>
                  </p>
                </div>
                <div className="info-box">
                  <div className="info-box_icon">
                    <i className="fal fa-envelope" />
                  </div>
                  <p className="info-box_text">
                    <a
                      href="mailto:support@faxtoninvestments.org"
                      className="info-box_link"
                    >
                      support@faxtoninvestments.org{" "}
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-xl-3">
           
          </div>
        </div>
      </div>
    </div>
    <div className="copyright-wrap">
      <div className="container">
        <p className="copyright-text text-center text-white">
          Copyright <i className="fal fa-copyright" /> 2023 By{" "}
          <a href="#">faxtoninvestments.org</a>. All Rights Reserved.
        </p>
      </div>
    </div>
  </div>
    </>
  )
}
