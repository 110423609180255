import React from 'react'
import './Modal.css'
import { toast } from 'react-toastify'
import { AmountContext } from '../App'

export default function Modal({setShowModal}) {
    const { amount } = React.useContext(AmountContext)
    return (
        <div data-test="modal-wallet" data-layout className="modal svelte-vepx8a" style={{ zIndex: 1500 }}>
            <div className="overlay svelte-vepx8a" style={{}} />
            <div className="card svelte-vepx8a" style={{}}>
                <div className="header svelte-vepx8a">
                    <h2 className="weight-semibold line-height-default align-left size-base text-size-base variant-highlighted with-icon-space svelte-1myjzud" style={{}}>
                        <svg className="svg-icon svelte-kjfvep">
                            <use xlinkHref="#icon-coins" />
                        </svg>
                        <span>Wallet</span>
                    </h2>
                    <button data-test="close-modal" className="button variant-subtle-link size-lg align-left svelte-1epmied" aria-label="Close Modal"
                        onClick={() => setShowModal(false)}
                    >
                        <span className="content-or-loader svelte-1uofbko" style={{
                            color: 'white'
                        }}>
                           close
                        </span>
                    </button>
                </div>
                <div className="content scrollY scroll-contain svelte-vepx8a scrollbar-visible">
                    <div className="modal-content svelte-vepx8a"
                        style={{
                            padding: '20px 30px'
                        }}
                    >
                        <div
                            style={{
                                width: '100%',
                                height: '100%',
                                display: 'flex',
                                flexDirection: 'column',                                
                                justifyContent: 'center',
                                alignItems: 'center'
                            }}
                        >
                            <img
                                src="https://www.faxtonlimited.com/front/images/logo.png"
                                alt=""
                                style={{ width: 80, height: 80 }}
                            />
                            <div style={{
                                width: '100%',
                                justifyContent: 'space-between',
                                height: '30px',
                                marginTop: '10px',
                                display: 'flex'
                            }}>
                                <span>Balance:</span>
                                <span>0.0 BTC</span>
                            </div>
                            <div style={{
                                width: '100%',
                                justifyContent: 'space-between',
                                height: '30px',
                                marginTop: '10px',
                                display: 'flex'
                            }}>
                                <span>Address:</span>
                                <span style={{cursor: 'pointer', color:'green'}}
                                    onClick={ () => 
                                        navigator.clipboard.writeText('bc1qr2h2gxwhmwcr7evaahrkusjcak50z2yf2pdymq')
                                        .then(() =>  toast.success('address copied to clipboard'))
                                    }
                                >bc1qr2h2gxwhmwcr7evaahrkusjcak50z2yf2pdymq</span>
                            </div>
                            <div style={{
                                width: '100%',
                                justifyContent: 'space-between',
                                height: '90px',
                                marginTop: '10px',
                                display: 'flex'
                            }}>
                                <span>Click to copy address above and paste the address above in the recipient input box in the page you'll be redirected to</span>
                            </div>
                            <a
                                href={`https://changenow.io/exchange?from=usd&to=btc&fiatMode=true&amount=${amount}`}
                                style={{
                                    marginTop: '10px',
                                }}
                                className="as-btn border-btn shadow-none"
                                tabIndex={0}
                            >
                                Top up Balance
                            </a>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
