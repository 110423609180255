import React from 'react'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'

export default function Forex() {
  return (
    <>
  {/*[if lte IE 9]> <p class="browserupgrade">You are using an <strong>outdated</strong> browser. Please <a
  href="https://browsehappy.com/">upgrade your browser</a> to improve your experience and security.</p>
<![endif]*/}
  {/********************************* Code Start From Here ******************************** */}
  {/*==============================Preloader==============================*/}
  {/*============================== Sidemenu============================== */}
  <div className="sidemenu-wrapper d-none d-lg-block ">
    <div className="sidemenu-content">
      <button className="closeButton sideMenuCls">
        <i className="far fa-times" />
      </button>
      <div className="widget footer-widget">
        <div className="as-widget-about">
          <div className="about-logo">
            <a href="/">
              <img
                src="https://www.faxtonlimited.com/landing/img/fico.png"
                alt="Faxton"
              />
            </a>
          </div>
          <p className="about-text">
            We specialize in trading and managing accounts and funds for her
            investors; ensuring that investors get their promised returns on
            investment. A few years ago the platform specially traded for a few
            employed members of the sponsor organisations and kept pushing to
            take it global.
          </p>
          <h4 className="footer-info-title">Follow Us On:</h4>
          <div className="as-social author-social">
            <a href="https://facebook.com/">
              <i className="fab fa-facebook-f" />
            </a>
            <a href="https://twitter.com/">
              <i className="fab fa-twitter" />
            </a>
            <a href="https://linkedin.com/">
              <i className="fab fa-linkedin-in" />
            </a>
            <a href="https://instagram.com/">
              <i className="fab fa-instagram" />
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div className="popup-search-box d-none d-lg-block">
    <button className="searchClose">
      <i className="fal fa-times" />
    </button>
    <form action="#">
      <input
        type="text"
        placeholder="What are you looking for?"
        data-ddg-inputtype="unknown"
      />
      <button type="submit">
        <i className="fal fa-search" />
      </button>
    </form>
  </div>
  {/*============================== Mobile Menu ============================== */}
  <div className="as-menu-wrapper">
    <div className="as-menu-area text-center">
      <button className="as-menu-toggle">
        <i className="fal fa-times" />
      </button>
      <div className="mobile-logo">
        <a href="/">
          <img
            src="https://www.faxtonlimited.com/front/images/logo.png"
            alt="Faxton"
            style={{ width: 80, height: 80 }}
          />
        </a>
      </div>
      <div className="as-mobile-menu">
        <ul>
          <li className="">
            <a href="/">Home</a>
          </li>
          <li className="">
            <a href="https://www.faxtonlimited.com/about-us">About</a>
          </li>
          <li className="">
            <a href="https://www.faxtonlimited.com/services">Services</a>
          </li>
          <li className="">
            <a href="https://www.faxtonlimited.com/pricing">Pricing</a>
          </li>
          <li className="">
            <a href="https://www.faxtonlimited.com/markets">Market</a>
          </li>
          <li className="">
            <a href="https://www.faxtonlimited.com/compounding">Compounding</a>
          </li>
          <li className="">
            <a href="https://www.faxtonlimited.com/contact">Contact</a>
          </li>
          <li className="menu-item-has-children as-item-has-children">
            <a href="#">
              Account
              <span className="as-mean-expand" />
            </a>
            <ul className="sub-menu as-submenu" style={{ display: "none" }}>
              <li>
                <a href="https://www.faxtonlimited.com/register">Register</a>
              </li>
              <li>
                <a href="https://www.faxtonlimited.com/login">Login</a>
              </li>
            </ul>
          </li>
          <li id="google_translate_element">
            <div className="skiptranslate goog-te-gadget" dir="ltr" style={{}}>
              <div id=":0.targetLanguage">
                <select
                  className="goog-te-combo"
                  aria-label="Language Translate Widget"
                  data-ddg-inputtype="unknown"
                >
                  <option value="">Select Language</option>
                  <option value="af">Afrikaans</option>
                  <option value="sq">Albanian</option>
                  <option value="am">Amharic</option>
                  <option value="ar">Arabic</option>
                  <option value="hy">Armenian</option>
                  <option value="as">Assamese</option>
                  <option value="ay">Aymara</option>
                  <option value="az">Azerbaijani</option>
                  <option value="bm">Bambara</option>
                  <option value="eu">Basque</option>
                  <option value="be">Belarusian</option>
                  <option value="bn">Bengali</option>
                  <option value="bho">Bhojpuri</option>
                  <option value="bs">Bosnian</option>
                  <option value="bg">Bulgarian</option>
                  <option value="ca">Catalan</option>
                  <option value="ceb">Cebuano</option>
                  <option value="ny">Chichewa</option>
                  <option value="zh-CN">Chinese (Simplified)</option>
                  <option value="zh-TW">Chinese (Traditional)</option>
                  <option value="co">Corsican</option>
                  <option value="hr">Croatian</option>
                  <option value="cs">Czech</option>
                  <option value="da">Danish</option>
                  <option value="dv">Dhivehi</option>
                  <option value="doi">Dogri</option>
                  <option value="nl">Dutch</option>
                  <option value="eo">Esperanto</option>
                  <option value="et">Estonian</option>
                  <option value="ee">Ewe</option>
                  <option value="tl">Filipino</option>
                  <option value="fi">Finnish</option>
                  <option value="fr">French</option>
                  <option value="fy">Frisian</option>
                  <option value="gl">Galician</option>
                  <option value="ka">Georgian</option>
                  <option value="de">German</option>
                  <option value="el">Greek</option>
                  <option value="gn">Guarani</option>
                  <option value="gu">Gujarati</option>
                  <option value="ht">Haitian Creole</option>
                  <option value="ha">Hausa</option>
                  <option value="haw">Hawaiian</option>
                  <option value="iw">Hebrew</option>
                  <option value="hi">Hindi</option>
                  <option value="hmn">Hmong</option>
                  <option value="hu">Hungarian</option>
                  <option value="is">Icelandic</option>
                  <option value="ig">Igbo</option>
                  <option value="ilo">Ilocano</option>
                  <option value="id">Indonesian</option>
                  <option value="ga">Irish Gaelic</option>
                  <option value="it">Italian</option>
                  <option value="ja">Japanese</option>
                  <option value="jw">Javanese</option>
                  <option value="kn">Kannada</option>
                  <option value="kk">Kazakh</option>
                  <option value="km">Khmer</option>
                  <option value="rw">Kinyarwanda</option>
                  <option value="gom">Konkani</option>
                  <option value="ko">Korean</option>
                  <option value="kri">Krio</option>
                  <option value="ku">Kurdish (Kurmanji)</option>
                  <option value="ckb">Kurdish (Sorani)</option>
                  <option value="ky">Kyrgyz</option>
                  <option value="lo">Lao</option>
                  <option value="la">Latin</option>
                  <option value="lv">Latvian</option>
                  <option value="ln">Lingala</option>
                  <option value="lt">Lithuanian</option>
                  <option value="lg">Luganda</option>
                  <option value="lb">Luxembourgish</option>
                  <option value="mk">Macedonian</option>
                  <option value="mai">Maithili</option>
                  <option value="mg">Malagasy</option>
                  <option value="ms">Malay</option>
                  <option value="ml">Malayalam</option>
                  <option value="mt">Maltese</option>
                  <option value="mi">Maori</option>
                  <option value="mr">Marathi</option>
                  <option value="mni-Mtei">Meiteilon (Manipuri)</option>
                  <option value="lus">Mizo</option>
                  <option value="mn">Mongolian</option>
                  <option value="my">Myanmar (Burmese)</option>
                  <option value="ne">Nepali</option>
                  <option value="no">Norwegian</option>
                  <option value="or">Odia (Oriya)</option>
                  <option value="om">Oromo</option>
                  <option value="ps">Pashto</option>
                  <option value="fa">Persian</option>
                  <option value="pl">Polish</option>
                  <option value="pt">Portuguese</option>
                  <option value="pa">Punjabi</option>
                  <option value="qu">Quechua</option>
                  <option value="ro">Romanian</option>
                  <option value="ru">Russian</option>
                  <option value="sm">Samoan</option>
                  <option value="sa">Sanskrit</option>
                  <option value="gd">Scots Gaelic</option>
                  <option value="nso">Sepedi</option>
                  <option value="sr">Serbian</option>
                  <option value="st">Sesotho</option>
                  <option value="sn">Shona</option>
                  <option value="sd">Sindhi</option>
                  <option value="si">Sinhala</option>
                  <option value="sk">Slovak</option>
                  <option value="sl">Slovenian</option>
                  <option value="so">Somali</option>
                  <option value="es">Spanish</option>
                  <option value="su">Sundanese</option>
                  <option value="sw">Swahili</option>
                  <option value="sv">Swedish</option>
                  <option value="tg">Tajik</option>
                  <option value="ta">Tamil</option>
                  <option value="tt">Tatar</option>
                  <option value="te">Telugu</option>
                  <option value="th">Thai</option>
                  <option value="ti">Tigrinya</option>
                  <option value="ts">Tsonga</option>
                  <option value="tr">Turkish</option>
                  <option value="tk">Turkmen</option>
                  <option value="ak">Twi</option>
                  <option value="uk">Ukrainian</option>
                  <option value="ur">Urdu</option>
                  <option value="ug">Uyghur</option>
                  <option value="uz">Uzbek</option>
                  <option value="vi">Vietnamese</option>
                  <option value="cy">Welsh</option>
                  <option value="xh">Xhosa</option>
                  <option value="yi">Yiddish</option>
                  <option value="yo">Yoruba</option>
                  <option value="zu">Zulu</option>
                </select>
              </div>
              Powered by{" "}
              <span style={{ whiteSpace: "nowrap" }}>
                <a
                  className="VIpgJd-ZVi9od-l4eHX-hSRGPd"
                  href="https://translate.google.com"
                  target="_blank"
                >
                  <img
                    src="https://www.gstatic.com/images/branding/googlelogo/1x/googlelogo_color_42x16dp.png"
                    width="37px"
                    height="14px"
                    style={{ paddingRight: 3 }}
                    alt="Google Translate"
                  />
                  Translate
                </a>
              </span>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
  {/*==============================Header Area==============================*/}
  <Navbar/>
  <div
    className="breadcumb-wrapper background-image"
    style={{
      backgroundImage:
        'url("https://www.faxtonlimited.com/front/assets/img/breadcumb/breadcumb-bg.jpg")'
    }}
  >
    <div className="container">
      <div className="breadcumb-content">
        <h1 className="breadcumb-title">Forex</h1>
        <ul className="breadcumb-menu">
          <li>
            <a href="/">Home</a>
          </li>
          <li>Forex</li>
        </ul>
      </div>
    </div>
  </div>
  <div className="row" style={{ padding: 50 }}>
    <h2>Forex Trading</h2>
    <div className="col-md-8">
      <p className="text-justify">
        The foreign exchange market is the most liquid in the world, with a
        daily trading volume of over $5 trillion.
      </p>
      <p className="text-justify">
        The advantages of investing in Forex can turn against you easily if you
        are not a professional trader. That’s why it is very important to avoid
        risking your funds trying to control such a complex market and it's
        better choose a professional Forex trading company in order to control,
        manage and make your funds grow.
      </p>
      <p className="text-justify">
        Keep your funds safe from the beginning and let our professional asset
        managers do what they know and love to do. Ensure steady Forex profits
        every trading day, while you enjoy your life doing your regular work,
        being with friends, traveling, etc.
      </p>
    </div>
    <div className="col-md-4">
      <img src="https://www.faxtonlimited.com/landing/img/02.jpeg" alt="" />
    </div>
  </div>
  {/*==============================Footer Area==============================*/}
  <Footer/>
  {/*********************************Code End Here ******************************** */}
  {/* Scroll To Top */}
  <div className="scroll-top">
    <svg
      className="progress-circle svg-content"
      width="100%"
      height="100%"
      viewBox="-1 -1 102 102"
    >
      <path
        d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98"
        style={{
          transition: "stroke-dashoffset 10ms linear 0s",
          strokeDasharray: "307.919, 307.919",
          strokeDashoffset: "307.919"
        }}
      />
    </svg>
  </div>
  {/*============================== All Js File============================== */}
  {/* Jquery */}
  {/*  */}
  {/* Slick Slider */}
  {/* Bootstrap */}
  {/* Magnific Popup */}
  {/* Counter Up */}
  {/* Range Slider */}
  {/* Isotope Filter */}
  {/* Flip Slider */}
  {/* Main Js File */}
  {/* Smartsupp Live Chat script */}
  <div
    id="goog-gt-tt"
    className="VIpgJd-yAWNEb-L7lbkb skiptranslate"
    style={{
      borderRadius: 12,
      margin: "0 0 0 -23px",
      padding: 0,
      fontFamily: '"Google Sans", Arial, sans-serif'
    }}
    data-id=""
  >
    <div id="goog-gt-vt" className="VIpgJd-yAWNEb-hvhgNd">
      <div className=" VIpgJd-yAWNEb-hvhgNd-l4eHX-i3jM8c">
        <img
          src="https://fonts.gstatic.com/s/i/productlogos/translate/v14/24px.svg"
          width={24}
          height={24}
          alt=""
        />
      </div>
      <div className=" VIpgJd-yAWNEb-hvhgNd-k77Iif-i3jM8c">
        <div className="VIpgJd-yAWNEb-hvhgNd-IuizWc" dir="ltr">
          Original text
        </div>
        <div
          id="goog-gt-original-text"
          className="VIpgJd-yAWNEb-nVMfcd-fmcmS VIpgJd-yAWNEb-hvhgNd-axAV1"
        />
      </div>
      <div className="VIpgJd-yAWNEb-hvhgNd-N7Eqid ltr">
        <div className="VIpgJd-yAWNEb-hvhgNd-N7Eqid-B7I4Od ltr" dir="ltr">
          <div className="VIpgJd-yAWNEb-hvhgNd-UTujCb">
            Rate this translation
          </div>
          <div className="VIpgJd-yAWNEb-hvhgNd-eO9mKe">
            Your feedback will be used to help improve Google Translate
          </div>
        </div>
        <div className="VIpgJd-yAWNEb-hvhgNd-xgov5 ltr">
          <button
            id="goog-gt-thumbUpButton"
            type="button"
            className="VIpgJd-yAWNEb-hvhgNd-bgm6sf"
            title="Good translation"
            aria-label="Good translation"
            aria-pressed="false"
          >
            <span id="goog-gt-thumbUpIcon">
              <svg
                width={24}
                height={24}
                viewBox="0 0 24 24"
                focusable="false"
                className="VIpgJd-yAWNEb-hvhgNd-THI6Vb NMm5M"
              >
                <path d="M21 7h-6.31l.95-4.57.03-.32c0-.41-.17-.79-.44-1.06L14.17 0S7.08 6.85 7 7H2v13h16c.83 0 1.54-.5 1.84-1.22l3.02-7.05c.09-.23.14-.47.14-.73V9c0-1.1-.9-2-2-2zM7 18H4V9h3v9zm14-7l-3 7H9V8l4.34-4.34L12 9h9v2z" />
              </svg>
            </span>
            <span id="goog-gt-thumbUpIconFilled">
              <svg
                width={24}
                height={24}
                viewBox="0 0 24 24"
                focusable="false"
                className="VIpgJd-yAWNEb-hvhgNd-THI6Vb NMm5M"
              >
                <path d="M21 7h-6.31l.95-4.57.03-.32c0-.41-.17-.79-.44-1.06L14.17 0S7.08 6.85 7 7v13h11c.83 0 1.54-.5 1.84-1.22l3.02-7.05c.09-.23.14-.47.14-.73V9c0-1.1-.9-2-2-2zM5 7H1v13h4V7z" />
              </svg>
            </span>
          </button>
          <button
            id="goog-gt-thumbDownButton"
            type="button"
            className="VIpgJd-yAWNEb-hvhgNd-bgm6sf"
            title="Poor translation"
            aria-label="Poor translation"
            aria-pressed="false"
          >
            <span id="goog-gt-thumbDownIcon">
              <svg
                width={24}
                height={24}
                viewBox="0 0 24 24"
                focusable="false"
                className="VIpgJd-yAWNEb-hvhgNd-THI6Vb NMm5M"
              >
                <path d="M3 17h6.31l-.95 4.57-.03.32c0 .41.17.79.44 1.06L9.83 24s7.09-6.85 7.17-7h5V4H6c-.83 0-1.54.5-1.84 1.22l-3.02 7.05c-.09.23-.14.47-.14.73v2c0 1.1.9 2 2 2zM17 6h3v9h-3V6zM3 13l3-7h9v10l-4.34 4.34L12 15H3v-2z" />
              </svg>
            </span>
            <span id="goog-gt-thumbDownIconFilled">
              <svg
                width={24}
                height={24}
                viewBox="0 0 24 24"
                focusable="false"
                className="VIpgJd-yAWNEb-hvhgNd-THI6Vb NMm5M"
              >
                <path d="M3 17h6.31l-.95 4.57-.03.32c0 .41.17.79.44 1.06L9.83 24s7.09-6.85 7.17-7V4H6c-.83 0-1.54.5-1.84 1.22l-3.02 7.05c-.09.23-.14.47-.14.73v2c0 1.1.9 2 2 2zm16 0h4V4h-4v13z" />
              </svg>
            </span>
          </button>
        </div>
      </div>
      <div
        id="goog-gt-votingHiddenPane"
        className="VIpgJd-yAWNEb-hvhgNd-aXYTce"
      >
        <form
          id="goog-gt-votingForm"
          action="//translate.googleapis.com/translate_voting?client=te"
          method="post"
          target="votingFrame"
          className="VIpgJd-yAWNEb-hvhgNd-aXYTce"
        >
          <input
            type="text"
            name="sl"
            id="goog-gt-votingInputSrcLang"
            data-ddg-inputtype="unknown"
          />
          <input
            type="text"
            name="tl"
            id="goog-gt-votingInputTrgLang"
            data-ddg-inputtype="unknown"
          />
          <input
            type="text"
            name="query"
            id="goog-gt-votingInputSrcText"
            data-ddg-inputtype="unknown"
          />
          <input
            type="text"
            name="gtrans"
            id="goog-gt-votingInputTrgText"
            data-ddg-inputtype="unknown"
          />
          <input
            type="text"
            name="vote"
            id="goog-gt-votingInputVote"
            data-ddg-inputtype="unknown"
          />
        </form>
        <iframe name="votingFrame" frameBorder={0} />
      </div>
    </div>
  </div>
  <div className="VIpgJd-ZVi9od-aZ2wEe-wOHMyf">
    <div className="VIpgJd-ZVi9od-aZ2wEe-OiiCO">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="VIpgJd-ZVi9od-aZ2wEe"
        width="96px"
        height="96px"
        viewBox="0 0 66 66"
      >
        <circle
          className="VIpgJd-ZVi9od-aZ2wEe-Jt5cK"
          fill="none"
          strokeWidth={6}
          strokeLinecap="round"
          cx={33}
          cy={33}
          r={30}
        />
      </svg>
    </div>
  </div>
  <iframe
    id="chat-application-iframe"
    title="Smartsupp"
    aria-hidden="true"
    style={{
      display: "block",
      position: "fixed",
      top: 0,
      left: 0,
      width: 1,
      height: 1,
      opacity: 0,
      border: "none",
      zIndex: -1,
      pointerEvents: "none"
    }}
  />
  <div id="smartsupp-widget-container">
    {" "}
    <div
      data-testid="widgetButtonFrame"
      style={{
        borderRadius: 9999,
        boxShadow:
          "rgba(0, 0, 0, 0.06) 0px 1px 6px 0px, rgba(0, 0, 0, 0.12) 0px 2px 32px 0px",
        height: 56,
        position: "fixed",
        bottom: 24,
        left: "initial",
        right: 12,
        zIndex: 10000000,
        width: 111
      }}
    >
      <iframe
        id="widgetButtonFrame"
        title="Smartsupp widget button"
        style={{
          position: "absolute",
          width: "100%",
          height: "100%",
          border: "none",
          display: "block",
          textAlign: "left",
          margin: 0,
          padding: 0,
          top: 0,
          left: 0,
          opacity: 1
        }}
        allowFullScreen=""
        scrolling="no"
      />
    </div>
  </div>
</>

  )
}
