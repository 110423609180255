import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from "./pages/Home";
import About from "./pages/About";
import Services from "./pages/Services";
import Prices from './pages/Prices';
import Market from './pages/Market';
import Compounding from './pages/Compounding';
import Contact from './pages/Contact';
import Login from './pages/Login';
import Register from './pages/Register';
import Partnershipplans from './pages/Partnershipplans';
import Affiliate from './pages/Partnershipplans';
import Termsandconditions from './pages/Termsandconditions';
import Privacy from './pages/Privacy';
import Crypto from './pages/Crypto';
import Forex from './pages/Forex';
import Cannabis from './pages/Cannabis';
import Escrow from './pages/Escrow';
import Renewableenergy from './pages/Renewableenergy';

export const AmountContext = React.createContext({
  amount: 0,
  showModal: false,
  user: null,
  setShowModal: () => {},
  setAmount: () => { },
})


const Amount = ({ children }) => {
  const [amount, setAmount] = React.useState(5000)
  const [showModal, setShowModal] = React.useState(false);
  const [user,setUser] = React.useState(null)

  React.useEffect(() => {
    const user  = JSON.parse(localStorage.getItem('user'));
    if(!user) {
      return;
    }
    setUser(user)
  },[])

  return (
    <AmountContext.Provider value={{
      amount,
      setAmount,
      showModal,
      user,
      setShowModal
    }}>
      {children}
    </AmountContext.Provider>
  )
}
function App() {
  return (
    <BrowserRouter>
      <Amount>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/services" element={<Services />} />
          <Route path="/pricing" element={<Prices />} />
          <Route path="/market" element={<Market />} />
          <Route path="/compounding" element={<Compounding />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/partnershipplans" element={<Partnershipplans />} />
          <Route path="/affiliate" element={<Affiliate />} />
          <Route path="/termsandconditions" element={<Termsandconditions />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/crypto" element={<Crypto />} />
          <Route path="/forex" element={<Forex />} />
          <Route path="/cannabis" element={<Cannabis />} />
          <Route path="/escrow" element={<Escrow />} />
          <Route path="/renewableenergy" element={<Renewableenergy />} />
        </Routes>
      </Amount>

    </BrowserRouter>
  );
}

export default App;
